<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.ProfileManage')" />

    <div class="layout_body">
      <section>
        <h6 class="allPage_tit">{{ $t('Profile.ProfileSetting') }}</h6>
        <div class="img_file_box">
          <span class="relative">
            <label for="file">
              <!-- 유저 프로필사진 무 -->
              <div
                v-if="!info.img_url"
                :class="`${url ? '' : 'border_none'} profil_img big`"
                style="width: 90px; height: 90px"
              >
                <img
                  :src="url"
                  :class="`${url ? '' : 'defaultImg'} image-bg-src `"
                />
              </div>
              <!-- 유저 프로필사진 존재-->
              <image-bg-src
                v-if="info.img_url"
                :src="info.img_url"
                class="profil_img big"
                style="width: 90px; height: 90px"
              />
            </label>
            <label class="img_file">
              <input
                id="file"
                type="file"
                accept="image/*"
                @change="handleChangeFile"
              />
            </label>
          </span>
        </div>
        <div class="joinForm_box modify_wrap">
          <ul class="wrap_flex userinfo">
            <li class="basic_txt">
              <label>아이디</label>
              <input
                type="text"
                :value="info.mem_userid"
                class="btn_group"
                readonly
              />
            </li>
            <li class="basic_txt">
              <label>{{ $t('Profile.UserGrade') }}</label>
              <input type="text" value="STARTER" class="btn_group" readonly />
            </li>
            <li class="basic_txt">
              <label>{{ $t('Profile.UserIntroduce') }}</label>
              <textarea
                v-model="mem_profile"
                class="profil_txtarea"
                maxlength="50"
                :placeholder="$t('Profile.InputIntro')"
              />
              <p class="align_right font2 textlen">
                <span class="txt_count">{{ mem_profile.length }}</span
                ><span>/50</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="btm_foot">
          <button type="button" @click="$router.push('/mypage')">
            {{ $t('MyStyle.Cancel') }}
          </button>
          <button type="button" @click="handleClickSubmit">
            {{ $t('MyStyle.UserInfoEdit') }}
          </button>
        </div>
      </section>
      <article class="inner p1430">
        <div class="bggray_box">
          <ul>
            <li v-html="$t('Profile.ProfileDescription1')"></li>

            <li>
              {{ $t('Profile.ProfileDescription2') }}
            </li>
          </ul>
        </div>
      </article>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import AuthAPI from '../../apis/AuthAPI';
import useAlert from '../../composables/dialogs/useAlert';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
export default {
  components: { SildFooter, BackTitleBasket, ImageBgSrc },
  setup() {
    const { info, setUserInfo } = useUserInfo();
    const { showAlert } = useAlert();
    const state = reactive({
      url: null,
      mem_profile: info.mem_profile ? info.mem_profile : '',
    });

    let file = null;

    return {
      info,
      ...toRefs(state),
      handleChangeFile(e) {
        file = e.target.files[0];

        state.url = URL.createObjectURL(file);
      },

      async handleClickSubmit() {
        const params = {
          file,
          mem_profile: state.mem_profile,
          mem_id: info?.mem_id,
        };

        const { data } = await AuthAPI.updateProfile(params);

        showAlert(data.msg);

        await setUserInfo(data.result_data);
      },
    };
  },
};
</script>

<style scoped>
.profil_img .image-bg-src {
  position: absolute;
  width: 90px;
  height: 90px;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
