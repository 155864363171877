<template>
  <div class="wrap">
    <back-title-basket title="입점/제휴문의" />

    <div class="layout_body">
      <div class="inner p04">
        <p class="pa24 font4 fontW500">
          SILD와 입점 관련 문의 주시면 <br />빠르게 연락 드리겠습니다.
          <span class="disblock icomail">apply@sild.co.kr</span>
        </p>
        <p class="align_center lineh">
          <span class="dott">표시는 필수항목입니다.</span>
        </p>
        <div class="order_discount type2">
          <ul class="pb40 wrap_flex order_inform color37">
            <li>
              <label><span class="dott type2">문의 유형</span></label>
              <div class="disflex">
                <label>
                  <input
                    type="radio"
                    value="입점문의"
                    name="type"
                    checked
                    @click="type = '입점문의'"
                  />
                  입점문의
                </label>
                <label>
                  <input
                    type="radio"
                    value="제휴문의"
                    name="type"
                    @click="type = '제휴문의'"
                  />
                  제휴문의
                </label>
              </div>
            </li>
            <li>
              <label><span class="dott type2">업체명</span></label>
              <input
                v-model="brand_name"
                type="text"
                class="btn_group width100"
                placeholder="업체명을 입력해주세요."
              />
            </li>
            <li>
              <label><span class="dott type2">담당자 이름</span></label>
              <input
                v-model="name"
                type="text"
                class="btn_group width100"
                placeholder="담당자 이름을 입력해주세요."
              />
            </li>
            <li>
              <label><span class="dott type2">브랜드 사이트</span></label>
              <input
                v-model="url"
                type="text"
                class="btn_group width100"
                placeholder="브랜드 사이트 주소를 입력해주세요."
              />
            </li>
            <li>
              <label class="font4">
                <span class="dott type2">연락처</span>
              </label>
              <div class="phone_input disflex">
                <select-box
                  v-model="phones[0]"
                  :items="phoneNumbers"
                  class="width33"
                />
                <input-number
                  v-model="phones[1]"
                  :maxLength="4"
                  class="btn_group width33"
                />
                <input-number
                  v-model="phones[2]"
                  :maxLength="4"
                  class="btn_group width33"
                />
              </div>
            </li>
            <li>
              <label><span class="dott type2">이메일</span></label>
              <input
                v-model="email"
                type="text"
                class="btn_group width100"
                placeholder="이메일을 입력해주세요."
              />
            </li>
            <li>
              <label><span class="dott type2">제목</span></label>
              <input
                v-model="title"
                type="text"
                class="btn_group width100"
                placeholder="제목을 입력해주세요."
              />
            </li>
            <li>
              <label><span class="dott type2">내용</span></label>
              <textarea
                v-model="content"
                class="bogray h120"
                placeholder="내용을 입력해주세요."
              ></textarea>
            </li>
            <li>
              <label>회사소개서첨부</label>
              <label v-if="file === null" class="fill_add">
                <input type="file" @change="handleChangeFile" />
                파일 첨부
              </label>

              <label v-else class="file_added">
                <input type="file" @change="handleChangeFile" />
                {{ file.name }}
              </label>
            </li>
            <li class="last_input">
              <label
                ><span class="dott type2"
                  >개인정보 수집 및 이용 동의</span
                ></label
              >
              <p class="disflex">
                <input
                  id="necessAge"
                  v-model="agree"
                  type="checkbox"
                  class="normal"
                />
                <label for="necessAge" class="chkbox cheked font2">
                  개인정보 수집 및 이용에 동의합니다.
                </label>
                <button
                  type="button"
                  class="white_btn btnw80h30 flex_right btn_pop_open"
                  @click="isShowPersonalInfo = true"
                >
                  내용보기
                </button>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="w100_btn_box disflex">
        <button
          type="reset"
          class="black_btn gray_btn font4"
          @click="$router.back()"
        >
          취소
        </button>
        <button
          type="summit"
          class="black_btn font4"
          @click="handleClickSubmit"
        >
          확인
        </button>
      </div>
    </div>

    <sild-footer />
    <personal-info
      v-if="isShowPersonalInfo"
      @close="isShowPersonalInfo = false"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import SelectBox from '../../components/Inputs/SelectBox/SelectBox.vue';
import InputNumber from '../../components/Inputs/InputNumber/InputNumber.vue';
import useToast from '../../composables/dialogs/useToast';
import PersonalInfo from '../auth/popups/PersonalInfo.vue';
import ServiceAPI from '@/apis/ServiceAPI';

export default {
  components: {
    SildFooter,
    BackTitleBasket,
    SelectBox,
    InputNumber,
    PersonalInfo,
  },

  setup(_, context) {
    const { addMsg } = useToast();
    const form = reactive({
      type: '입점문의',
      brand_name: '',
      title: '',
      name: '',
      url: '',
      email: '',
      content: '',
      phones: [],
      agree: false,
      file: null,
    });

    const isShowPersonalInfo = ref(false);

    const validate = () => {
      if (form.brand_name === '') {
        addMsg('업체명을 입력해주세요.');
        return false;
      }

      if (form.name === '') {
        addMsg('담당자 이름을 입력해주세요.');
        return false;
      }

      if (form.url === '') {
        addMsg('브랜드 사이트 주소를 입력해주세요.');
        return false;
      }

      const phone = form.phones.join('');

      if (!/^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/.test(phone)) {
        addMsg('담당자 연락처를 확인해주세요.');
        return false;
      }

      const emailCheck =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

      if (!emailCheck.test(form.email)) {
        addMsg('이메일을 확앤해주세요.');
        return false;
      }

      if (form.title === '') {
        addMsg('제목을 입력해주세요.');
        return false;
      }

      if (form.content === '') {
        addMsg('내용을 입력해주세요.');
        return false;
      }

      if (form.agree === false) {
        addMsg('개인정보 수집 및 이용 동의에 동의해주세요.');
        return false;
      }

      return true;
    };

    return {
      ...toRefs(form),
      isShowPersonalInfo,
      phoneNumbers,
      handleChangeFile(e) {
        form.file = e.target.files[0];
        console.log(e.target.files[0]);
      },
      async handleClickSubmit() {
        if (validate()) {
          const { data } = await ServiceAPI.inquiry({
            ...form,
            phone: form.phones.join(''),
          });

          if (data.status) {
            addMsg(`${form.type} 신청이 완료되었습니다.`);
            context.root.$router.back();
          }
        }
      },
    };
  },
};
const phoneNumbers = ['010', '011', '017', '019', '070'].map(val => ({
  key: val,
  label: val,
}));
</script>

<style>
.file_added {
  display: block;
  line-height: 40px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  border: 1px solid #a8a8a8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 15px;
}
</style>
