import { axios } from '@/plugins/axios';

export default {
  getMyCoupons(params) {
    return axios.post('/coupon/getMyCoupons', params);
  },
  addCoupon(params) {
    return axios.post('/coupon/addCoupon', params);
  },
  getBrandCoupons(params) {
    return axios.post('/coupon/getBrandCoupons', params);
  },
  getCouponsGroupByBrand(params) {
    return axios.post('/coupon/getCouponsGroupByBrand', params);
  },
  downloadCoupon(params) {
    return axios.post('/coupon/downloadCoupon', params);
  },
};
