<template>
  <button
    v-if="item"
    type="button"
    class="conp_type"
    @click="handleClickButton"
  >
    <template v-if="fileName">
      <image-block-file :fileName="fileName" class="conp_img" />
    </template>
    <template v-else>
      <image-bg-src
        :src="item.image"
        :class="`conp_img ${checked ? 'on' : ''}`"
      />
    </template>
    <div class="absol_bg">
      <div class="conp_opacity"></div>
      <div class="conp_check"></div>
    </div>
    <p>{{ item.label }}</p>
  </button>
</template>

<script>
import { ref } from 'vue-demi';
import ImageBgSrc from '../../ui/ImageBlock/ImageBgSrc.vue';
import ImageBlockFile from '../../ui/ImageBlock/ImageBlockFile.vue';

export default {
  components: { ImageBgSrc, ImageBlockFile },
  props: {
    item: {},
    fileName: {},
    isChecked: { default: true },
    value: { default: false },
  },
  setup(props, context) {
    const checked = ref(props.value);

    return {
      checked,
      handleClickButton() {
        if (props.isChecked) {
          checked.value = !checked.value;
        }

        context.emit('input', checked.value);
        context.emit('click', checked.value);
      },
    };
  },
};
</script>

<style></style>
