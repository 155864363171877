<template>
  <div class="border_top">
    <!--리뷰 제품-->
    <div class="review_con ver2 disflex">
      <image-bg-src
        :baseURL="item.is_gubun !== '일반연동' ? item.mem_img_url : undefined"
        :src="item.is_gubun === '일반연동' ? item.cit_file_1 : undefined"
        class="review_pro"
      />
      <div class="detail_pro right">
        <p class="font2 ellipsis">
          {{ item.cit_name }}
        </p>
        <span>{{ item.cde_title }}</span>
        <span
          >{{ $t('Button.ShippingDate') }}
          {{
            item.delivery_dtm
              ? item.delivery_dtm.substr(0, 10)
              : Intl.DateTimeFormat('kr').format(new Date())
          }}</span
        >
      </div>
    </div>
    <!--리뷰 제목/ 클릭할 제목-->
    <div class="inner ptb20" @click="isOpen = !isOpen">
      <div class="persnal_text arr_up disblock">
        <review-score :value="item.cre_score" />
        <h3>{{ item.cre_title }}</h3>
      </div>
    </div>

    <review-item v-show="isOpen" :item="item">
      <div class="btm_foot">
        <button type="button" class="font2" @click="handleClickDelete(item)">
          {{ $t('Basket.delete') }}
        </button>
        <button
          type="button"
          class="btn_pop_open font2"
          @click="handleClickUpdate(item)"
        >
          {{ $t('Ect.Edit') }}
        </button>
      </div>
    </review-item>
    <review-write-view
      v-if="isShowEdit"
      :reviewItem="updateItem"
      @close="isShowEdit = false"
      :cit_id="updateItem.cit_id"
      :cre_id="props.cre_id"
      @submit="handleEditReview"
      @update="handleUpdate"
    />
  </div>
</template>

<script>
import ReviewItem from '@Views/products/components/ReviewItem.vue';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import ReviewScore from '../ReviewScore/ReviewScore.vue';
import { reactive, ref, toRefs } from 'vue-demi';

import ReviewWriteView from '@Views/products/ReviewWriteView';

export default {
  props: ['item', 'cre_id'],
  components: {
    ReviewWriteView,
    ReviewItem,
    ImageBgSrc,
    ReviewScore,
  },
  setup(props, context) {
    const isOpen = ref(true);
    const state = reactive({
      list: [],
      product: null,
      defaultItem: null,
      isShowEdit: false,
      updateItem: null,
      creId: null,
    });

    console.log(props.item.cit_file_1, '????????');
    return {
      ...toRefs(state),
      props,
      isOpen,
      handleUpdate(statue) {
        context.emit('updateReview', statue);
      },
      handleClickUpdate(reviewItem) {
        state.creId = reviewItem.cre_id;

        state.updateItem = reviewItem;
        console.log(state.updateItem);
        // context.root.$router.push(
        //   `/product/review-write/${reviewItem.cit_id}/${props.cre_id}`
        // );
        state.isShowEdit = true;
      },
      handleEditReview(params) {
        state.updateItem = null;
        state.isShowEdit = false;

        context.emit('submit', params);
      },
      handleClickDelete(item) {
        context.emit('deleteReview', item);
      },
    };
  },
};
</script>

<style></style>
