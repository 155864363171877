<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.InfoEdit')" />
    <div class="layout_body">
      <div class="login_wrap modify_wrap">
        <div class="logo_box wrap_flex center_flex">
          <p class="font2">
            {{ $t('InfoEdit.ReLogin') }}
          </p>
        </div>

        <ul class="login_form">
          <li class="pb10 basic_txt">
            <!-- 이메일아이디는 미리 입력되어있어야함 -->
            <input v-model="form.mem_userid" type="text" />
          </li>
          <li class="pb10">
            <label for="password"></label>
            <input
              id="password"
              v-model="form.mem_password"
              type="password"
              :placeholder="$t('InfoEdit.InputPw')"
              @keydown="handleKeydown"
            />
          </li>
        </ul>

        <button
          type="button"
          class="black_btn font4"
          @click="handleClickConfirm"
        >
          {{ $t('Join.Confirm') }}
        </button>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import AuthAPI from '@/apis/AuthAPI';
import useAlert from '../../composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket, SildFooter },
  setup(_, context) {
    const { info } = useUserInfo();
    const { showAlert } = useAlert();

    if (info.is_createid === 'n') {
      context.root.$router.replace('/mypage/create-info-form');
    }

    const state = reactive({
      form: {
        mem_userid:
          info.mem_sns_type == 'email' ? info.mem_userid : info.mem_email,
        mem_password: '',
      },
    });

    return {
      ...toRefs(state),
      info,

      async handleClickConfirm() {
        const { data } = await AuthAPI.login(state.form);

        if (data.result === false) {
          showAlert(context.root.$t('Join.InputPw'));
          return;
        }
        if (data.result) {
          context.root.$router.push('/mypage/update-info-form');
        }
      },

      handleKeydown(e) {
        if (e.key === 'Enter') {
          this.handleClickConfirm();
        }
      },
    };
  },
};
</script>

<style></style>
