<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.InfoEdit')" />
    <div class="layout_body">
      <div class="top_txtbox">
        <p>{{ $t('InfoEdit.AlertLogin', { mail: info.mem_email }) }}</p>
        <p v-html="$t('InfoEdit.AlertCreate')"></p>
      </div>

      <div class="joinForm_box modify_wrap">
        <form action="">
          <div>
            <ul class="wrap_flex userinfo">
              <li>
                <label for="mem_userid">아이디</label>
                <input
                  id="mem_userid"
                  v-model="form.mem_userid"
                  type="text"
                  name="mem_userid"
                  maxLength="15"
                  :placeholder="$t('Join.EnterIdMixed')"
                  @input="handleInputId"
                  @focusout="handleFocusOutName"
                />
                <p v-show="error.duplicateId" class="error">
                  {{ $t('Join.ErrorOverlapId') }}
                </p>
                <p v-show="error.mem_userid" class="error">
                  {{ $t('Join.ErrorId') }}
                </p>
                <p v-show="error.sameNumberId" class="error">
                  {{ $t('Join.ErrorIdThree') }}
                </p>
              </li>
              <li>
                <label for="mem_password">{{ $t('Join.Password') }}</label>
                <input
                  id="mem_password"
                  v-model="form.mem_password"
                  type="password"
                  name="mem_password"
                  maxLength="15"
                  :placeholder="$t('Join.EnterPw')"
                  @input="handleInputPw"
                  @focusout="handleFocusOutName"
                />
                <p v-show="error.mem_password" class="error">
                  {{ $t('Join.ErrorPwMixed') }}
                </p>
                <p v-show="error.sameNumberPw" class="error">
                  {{ $t('Join.ErrorPwSequence') }}
                </p>
              </li>
              <li>
                <label for="userPwchk">{{ $t('Join.UserPwAgain') }}</label>
                <input
                  id="userPwchk"
                  v-model="form.pwCk"
                  type="password"
                  name="pwCk"
                  maxLength="15"
                  :placeholder="$t('Join.EnterPwAgain')"
                  @input="handleInputPwChk"
                  @focusout="handleFocusOutName"
                />
                <p v-show="error.pwCk" class="error">
                  {{ $t('Join.ErrorPwSame') }}
                </p>
              </li>
              <li>
                <a
                  class="black_btn font4 w100imp"
                  style="margin: 0"
                  @click="handleClickSubmit"
                >
                  {{ $t('Join.Confirm') }}</a
                >
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import AuthAPI from '@/apis/AuthAPI';
import useAlert from '../../composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket, SildFooter },
  setup(_, context) {
    const { info, setUserInfo } = useUserInfo();
    const { showAlert } = useAlert();

    const state = reactive({
      error: {},
      form: {
        mem_id: info?.mem_id,
        mem_userid: '',
        mem_password: '',
        pwCk: '',
      },
    });

    const validateObj = {
      mem_userid(value) {
        const reg = /^[a-z]+[a-z0-9]{5,14}$/g;
        const error = {
          mem_userid: false,
          sameNumberId: false,
        };

        if (!reg.test(value)) {
          error.mem_userid = true;
        } else {
          error.sameNumberId = checkSameNumber(value);
        }

        return error;
      },
      mem_password(value) {
        let reg = /(?=.*?[a-z])(?=.*?[A-Z])/;
        let reg2 = /[~!@#$%^&*()_+|<>?:{}]/;
        const error = {
          mem_password: false,
          sameNumberPw: false,
        };

        if (!reg.test(value) || !reg2.test(value) || value.length < 6) {
          error.mem_password = true;
        } else {
          error.sameNumberPw = checkSameNumber(value);
        }

        return error;
      },
      pwCk(value) {
        const error = {
          pwCk: false,
        };

        error.pwCk = value !== state.form.mem_password;
        return error;
      },
    };

    const isDuplicateId = async () => {
      const { data } = await AuthAPI.checkId(state.form['mem_userid']);
      return data.count > 0;
    };

    const validateByName = (name) => {
      if (typeof validateObj[name] === 'object') {
        const error = {};

        error[name] = !validateObj[name].test(state.form[name]);

        return error;
      }

      return validateObj[name](state.form[name]);
    };

    const validate = async () => {
      await validateInputValues();

      let isSubmit = true;

      Object.keys(state.error).forEach((key) => {
        if (isSubmit) isSubmit = !state.error[key];
      });

      return isSubmit;
    };

    const validateInputValues = async () => {
      let error = {};

      Object.keys(validateObj).forEach((key) => {
        error = { ...error, ...validateByName(key) };
      });
      error.duplicateId = await isDuplicateId();

      state.error = error;
    };

    return {
      info,

      ...toRefs(state),

      async handleFocusOutName(e) {
        const error = validateByName(e.target.name);

        if (e.target.name === 'mem_userid') {
          error.duplicateId = await isDuplicateId();
        }

        state.error = { ...state.error, ...error };
      },
      handleInputId() {
        state.form.mem_userid = state.form.mem_userid.toLowerCase();
      },
      handleInputPw() {
        return state.form.mem_password;
        // state.form.mem_password = state.form.mem_password.toLowerCase();
      },
      handleInputPwChk() {
        return state.form.pwCk;
        // state.form.pwCk = state.form.pwCk.toLowerCase();
      },
      async handleClickSubmit(e) {
        e.preventDefault();

        if (!(await validate())) {
          return showAlert(context.root.$t('InfoEdit.InputEssential'));
        }

        const { data } = await AuthAPI.createSnsId(state.form);

        await setUserInfo(data.result_data);

        showAlert(context.root.$t('InfoEdit.UserCreate'));

        context.root.$router.replace('/mypage/update-info-form');
      },
    };
  },
};

const sameNumber = ['012', '123', '234', '345', '456', '567', '678', '789'];

const checkSameNumber = (value) => {
  let isSameNumber = false;

  sameNumber.forEach((str) => {
    if (isSameNumber === false) {
      isSameNumber = value.indexOf(str) > -1;
    }
  });

  return isSameNumber;
};
</script>

<style></style>
