<template>
  <div class="digital">
    <div class="disflex">
      <div class="left_box" style="flex: 5">
        <h6>{{ item.cit_name }}</h6>
        <span class="font2">
          {{ item.cit_summary }}
        </span>
        <ul class="wrap_flex">
          <li>BRAND</li>
          <li>{{ item.den_name }}</li>
          <li>OWNER</li>
          <li>{{ item.owner_id }}</li>
          <li>ISSUED DATE</li>
          <li>{{ getIssuedDate() }}</li>
        </ul>
      </div>
      <div class="right_box" style="flex: 3">
        <image-bg-src :src="item.image_uri" />
      </div>
    </div>
    <div v-if="histories && histories.length > 0" class="mt20 light_tb">
      <h6 class="fontEn font2 fontWb mb10">OWNER HISTORY</h6>
      <div :class="histories.length === history.length ? 'is-end-history' : ''">
        <table class="width100">
          <colgroup>
            <col style="width: 30.7%" />
            <col style="width: 30.7%" />
            <col style="width: 38.5%" />
          </colgroup>
          <thead>
            <tr>
              <th>FROM</th>
              <th>TO</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in histories" :key="val.created_at">
              <td>{{ val.from_mem_userid }}</td>
              <td>{{ val.to_mem_userid }}</td>
              <td>{{ getHistoryDate(val.created_at) }} days ago</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        v-if="histories.length < history.length"
        type="button"
        class="add right fontEn"
        @click="handleClickMore"
      >
        MORE
      </button>
    </div>

    <router-link
      v-if="isShowButton"
      :to="`/mypage/digital/${item.token_id}/${item.token_seq}/${item.mem_id}`"
      class="line40B mt20"
    >
      {{ $t('Guarantee.GuaranteeManage') }}
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import { ref } from 'vue-demi';
export default {
  components: { ImageBgSrc },
  props: ['item', 'history', 'isShowButton'],
  setup(props) {
    const { info } = useUserInfo();

    let page = 1;
    const limit = 3;

    const histories = ref();

    const getHistories = () => {
      histories.value = props.history.slice(0, page * limit);
    };

    props.history && getHistories();

    return {
      info,
      histories,
      getIssuedDate() {
        return moment(props.item.created_at).format('YYYY-MM-DD');
      },

      handleClickMore() {
        page++;
        getHistories();
      },

      getHistoryDate(date) {
        const from = moment(date);
        const to = moment();

        return to.diff(from, 'days');
      },
    };
  },
};
</script>

<style>
.is-end-history {
  border-bottom: 1px solid #c7c7c7 !important;
}
</style>
