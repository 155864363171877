<template>
  <down-full-up-popup
    :title="$t('Join.UsageTerm')"
    class="usage-popup"
    @close="$emit('close')"
  >
    <div slot="body" v-html="info.terms" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import useShopInfo from '@/composables/shop/useShopInfo';

export default {
  components: { DownFullUpPopup },
  setup() {
    const { info } = useShopInfo();
    console.log('@@@@@@@@@@@@@@@@@@@@@@@');
    console.log(info);
    console.log('@@@@@@@@@@@@@@@@@@@@@@@');
    return {
      info,
    };
  },
};
</script>

<style>
.usage-popup .pop_body * {
  font-size: 0.85rem;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
</style>
