<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.Coupon')" />
    <div class="layout_body box_p60">
      <div class="list_layout info_tit_box">
        <div class="inner p04">
          <div class="info_tit coupon">
            <p v-html="$t('Coupon.Subject', { couponNum: myCouponCount })"></p>
          </div>
        </div>
        <div class="border_top">
          <div class="allPage_tit disflex max40">
            {{ $t('Coupon.MobileCoupon') }}
            <div class="tooltip">
              <div class="tooltip-content">
                <p v-html="$t('Coupon.CouponTooltip')"></p>
              </div>
            </div>
          </div>

          <div class="inner userinfo">
            <div class="disflex">
              <label for=""></label>
              <input
                ref="couponNumber"
                :placeholder="$t('Coupon.AlertHyphen')"
                class="btn_group middle_type"
                maxlength="14"
                type="text"
                @keyup="handleKeydownCouponNumber"
              />
              <button
                class="black_btn btn_pop_open"
                @click="handleClickCouponNumber"
              >
                {{ $t('Coupon.Verification') }}
              </button>
            </div>
          </div>
        </div>
        <div class="border_top">
          <div class="inner p20">
            <div class="relative mb10">
              <input
                ref="couponName"
                :placeholder="$t('Coupon.AlertCouponName')"
                class="gray_search ver2"
                maxlength="15"
                type="search"
                @keyup="handleKeyupKeyword"
              />
              <button
                class="btnClear"
                type="button"
                @click="handleClickSearch"
              />
            </div>
            <ul class="sub_tab disflex">
              <li :class="`width25${gigan === 1 ? ' on' : ''}`">
                <button type="button" @click="handleClickGigan(1)">
                  {{ $t('Period.Week') }}
                </button>
              </li>
              <li :class="`width25${gigan === 2 ? ' on' : ''}`">
                <button type="button" @click="handleClickGigan(2)">
                  {{ $t('Period.Month') }}
                </button>
              </li>
              <li :class="`width25${gigan === 3 ? ' on' : ''}`">
                <button type="button" @click="handleClickGigan(3)">
                  {{ $t('Period.SixMonth') }}
                </button>
              </li>
              <li :class="`width25${gigan === 4 ? ' on' : ''}`">
                <button type="button" @click="handleClickGigan(4)">
                  {{ $t('Period.Year') }}
                </button>
              </li>
            </ul>
            <div class="calenda_input mt10">
              <div class="wrap_flex">
                <label for="">
                  <input v-model="startDate" type="date" />
                </label>

                <i>~</i>

                <label for="">
                  <input v-model="endDate" type="date" />
                </label>

                <button
                  class="white_search"
                  type="button"
                  @click="handleChangeGigan"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div class="border_top">
          <div class="header_tab">
            <ul class="disflex">
              <li
                :class="`width50${is_use === 'n' ? ' on' : ''}`"
                @click="handleClickIsUse('n')"
              >
                <a class="current" data-tab="tab-1" href="#">{{
                  $t('Coupon.HoldingCoupon')
                }}</a>
              </li>
              <li
                :class="`width50${is_use === 'y' ? ' on' : ''}`"
                @click="handleClickIsUse('y')"
              >
                <a class="" data-tab="tab-2" href="#">{{
                  $t('Coupon.UsedCoupon')
                }}</a>
              </li>
            </ul>
          </div>
          <div class="inner p20">
            <div class="noswiper_coupon coupon_swiper tab-content current">
              <ul v-if="coupons.length > 0">
                <coupon-item
                  v-for="item in coupons"
                  :key="item.ccl_id"
                  :isOn="false"
                  :item="item"
                />
                <li
                  v-if="coupons.length < total"
                  class="more"
                  @click="handleClickMore"
                >
                  {{ $t('Ect.More') }}
                </li>
              </ul>
              <div v-else class="no-coupon">
                {{ is_use === 'n' ? $t('Coupon.Holding') : $t('Coupon.Used')
                }}{{ $t('Coupon.NoneCoupon') }}
              </div>
            </div>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Coupon.CouponTip') }}</h6>
          <div class="inner">
            <ul class="order_tip cancel_tip">
              <li>{{ $t('Coupon.CouponDescription1') }}</li>
              <li>{{ $t('Coupon.CouponDescription2') }}</li>
              <li>{{ $t('Coupon.CouponDescription3') }}</li>
              <li>{{ $t('Coupon.CouponDescription4') }}</li>
              <li>{{ $t('Coupon.CouponDescription5') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import CouponItem from '../../../components/ui/CouponItem/CouponItem.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import CouponAPI from '@/apis/CouponAPI';
import { reactive, toRefs } from 'vue-demi';
import useAlert from '../../../composables/dialogs/useAlert';
import moment from 'moment';

export default {
  components: { CouponItem, BackTitleBasket },
  setup(props, context) {
    const { info } = useUserInfo();
    const { showAlert } = useAlert();

    const state = reactive({
      coupons: [],
      is_use: 'n',
      gigan: 1,
      total: 0,
      myCouponCount: 0,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(7, 'Days').format('YYYY-MM-DD'),
    });

    const refs = reactive({
      couponName: null,
      couponNumber: null,
    });

    const params = {
      page: 0,
      limit: 5,
    };

    const loadDatas = async () => {
      const { data } = await CouponAPI.getMyCoupons({
        ...params,
        keyword: refs.couponName ? refs.couponName.value : '',
        mem_id: info?.mem_id,
        is_use: state.is_use,
        startDate: state.startDate,
        endDate: state.endDate,
      });
      console.log('-> ', data);

      state.total = data.total.total;
      state.coupons = [...state.coupons, ...data.result_data];
      state.myCouponCount = data.use_total.total;
    };

    const addCoupon = async () => {
      const { data } = await CouponAPI.addCoupon({
        mem_id: info?.mem_id,
        couponNumber: refs.couponNumber.value,
      });
      console.log('-> cupone', data);

      if (data.result) {
        showAlert(context.root.$t('Coupon.AlertIssued'));
        state.myCouponCount++;
        state.coupons = [data.coupon, ...state.coupons];
      } else {
        showAlert(context.root.$t('Coupon.AlertNumber'));
      }
    };

    loadDatas();

    const clearParams = () => {
      params.page = 0;
      state.coupons = [];
    };

    const clearAndLoadDatas = () => {
      clearParams();
      loadDatas();
    };

    return {
      params,
      addCoupon,
      ...toRefs(refs),
      ...toRefs(state),
      handleClickGigan(gigan) {
        state.gigan = gigan;
        const date = moment();

        state.startDate = date.format('YYYY-MM-DD');

        switch (gigan) {
          case 1:
            state.endDate = date.add(7, 'Days').format('YYYY-MM-DD');
            break;
          case 2:
            state.endDate = date.add(1, 'Months').format('YYYY-MM-DD');
            break;
          case 3:
            state.endDate = date.add(6, 'Months').format('YYYY-MM-DD');
            break;
          case 4:
            state.endDate = date.add(1, 'Years').format('YYYY-MM-DD');
            break;
          default:
            break;
        }

        clearAndLoadDatas();
      },
      handleClickIsUse(type) {
        state.is_use = type;
        clearAndLoadDatas();
      },
      handleClickMore() {
        params.page++;
        loadDatas();
      },
      handleKeyupKeyword(e) {
        if (e.key === 'Enter') {
          clearAndLoadDatas();
        }
      },
      handleClickSearch() {
        clearAndLoadDatas();
      },
      handleKeydownCouponNumber(e) {
        e.target.value = e.target.value.toUpperCase();
      },
      handleClickCouponNumber() {
        addCoupon();
      },
      handleChangeGigan() {
        context.emit('input', [state.startDate, state.endDate]);
        context.emit('change', [state.startDate, state.endDate]);

        clearAndLoadDatas();
      },
    };
  },
};
</script>

<style>
.no-coupon {
  padding: 20px 0;
  text-align: center;
}
.more {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
</style>
