import { sinkerAPI as axios } from '@/plugins/axios';

export default {
  getWarranty(id, seq) {
    return axios().get(`/nft/sync/warranty/${id}/${seq}/history`);
  },
  getWarrantyList(mem_id, params) {
    return axios().get(`/nft/sync/member/${mem_id}/warranty`, { params });
  },
  sendWarranty(params) {
    return axios().put(`/nft/sync/warranty`, params);
  },
};
