<template>
  <div v-if="item" class="wrap">
    <header class="layout_header new_page_header">
      <div class="hinner disflex">
        <back-button v-if="isOwner" />
        <h2 class="new_title brand_name">
          {{ $t('Guarantee.GuaranteeManage') }}
        </h2>
        <basket-button v-if="isOwner" />
      </div>
      <slot />
    </header>

    <div class="layout_body">
      <div class="list_layout">
        <div class="inner">
          <digital-warranty-item :item="item" :history="history" />
        </div>
        <div v-if="info.mem_id === item.mem_id" class="border_top">
          <h6 class="allPage_tit">{{ $t('Guarantee.IDSend') }}</h6>
          <div class="inner p20 userinfo">
            <div class="disflex">
              <label for="id_ent"></label>
              <input
                id="id_ent"
                v-model="id"
                type="text"
                :placeholder="$t('Guarantee.AlertId')"
                class="btn_group normal_type"
              />
              <button class="black_btn btn_pop_open" @click="handleClickSend">
                {{ $t('Ect.Send') }}
              </button>
            </div>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Guarantee.Share') }}</h6>
          <div class="inner p20 userinfo">
            <div class="disflex">
              <label for="id_ent"></label>
              <input
                id="id_ent"
                type="text"
                class="btn_group normal_type"
                :value="url"
                readonly
              />
              <button class="black_btn btn_pop_open" @click="handleClickShare">
                {{ $t('Guarantee.Share') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DigitalAPI from '@/apis/DigitalAPI';
import AuthAPI from '@/apis/AuthAPI';
import useToast from '../../../composables/dialogs/useToast';
import { reactive, toRefs } from 'vue-demi';
import useConfirm from '../../../composables/dialogs/useConfirm';
import useUserInfo from '../../../composables/user/useUserInfo';
import useShare from '../../../composables/dialogs/useShare';
import DigitalWarrantyItem from '../../../components/ui/DigitalWarrantyItem/DigitalWarrantyItem.vue';
import BackButton from '../../../components/ui/Buttons/BackButton.vue';
import BasketButton from '../../../components/layouts/components/headers/components/BasketButton/BasketButton.vue';
export default {
  components: { DigitalWarrantyItem, BackButton, BasketButton },
  setup(_, context) {
    const token_id = context.root.$route.params.id;
    const token_seq = context.root.$route.params.seq;
    const owner_id = context.root.$route.params.owner_id;

    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const { info } = useUserInfo();
    const { showShare } = useShare();

    // 로그인 되어있지 않은 경우 로그인창으로 이동
    if (!info) {
      localStorage.setItem('return.page', context.root.$route.path);
      context.root.$router.push({
        path: '/auth/login',
      });
    }

    const state = reactive({
      id: '',
      item: null,
      history: null,
    });

    const nomalize = {};

    const setHistories = async (histories, owner_id) => {
      const userIds = new Set();

      userIds.add(owner_id);

      histories.forEach(item => {
        userIds.add(item.to_mem_id);
        userIds.add(item.from_mem_id);
      });

      const { data } = await AuthAPI.getUserIds([...userIds]);

      data.forEach(item => {
        nomalize[item['mem_id']] = item['mem_userid'];
      });

      state.history = histories.map(item => ({
        ...item,
        to_mem_userid: nomalize[item.to_mem_id],
        from_mem_userid: nomalize[item.from_mem_id],
      }));
    };

    const loadDatas = async () => {
      const { data } = await DigitalAPI.getWarranty(token_id, token_seq);

      const item = data.body.result.warranty;

      await setHistories(data.body.result.items, item.mem_id);

      state.item = {
        ...item,
        owner_id: nomalize[item.mem_id],
      };
    };

    loadDatas();

    const maskUsername = name => {
      const chars = name.split('');

      return chars
        .map((char, idx) =>
          idx === 0 || chars.length - 1 === idx ? char : '*',
        )
        .join('');
    };

    const sendDigitalWarranty = async to_mem_id => {
      const { data } = await DigitalAPI.sendWarranty({
        from_mem_id: info?.mem_id,
        to_mem_id,
        token_id,
        token_seq,
      });

      if (data.statusCode === 200) {
        addMsg(context.root.$t('Guarantee.GuaranteeComplete'));
        loadDatas();
      }
    };

    return {
      info,
      ...toRefs(state),
      url: location.href,
      isOwner: owner_id === info?.mem_id,
      async handleClickSend() {
        if (state.id === '') {
          addMsg(context.root.$t('Guarantee.AlertId'));
          return;
        }

        const { data } = await AuthAPI.checkId(state.id);

        if (data.count === 0) {
          addMsg(context.root.$t('Guarantee.AlertNoneUser'));
          return;
        }

        const name = maskUsername(data.user.mem_username);
        const msg = context.root.$t(
          'Guarantee.AlertPost',
          { Post: '보증서보내기' },
          { UserName: name },
        );

        showConfirm(msg, () => {
          sendDigitalWarranty(data.user.mem_id);
        });
      },
      handleClickShare() {
        showShare({
          url: location.href,
          title: '테스트 보증서',
          image: `https://sild-fileserver.s3.ap-northeast-2.amazonaws.com/prod/product/2022/06/24/7d7929b6-28a2-4c53-896d-5baa61e0144d.jpg`,
          desc: '디지털보증서 입니다ㅓ.',
        });
      },
    };
  },
};
</script>

<style></style>
