<template>
  <div v-if="styles" :key="styles.length" class="wrap">
    <back-title-basket :title="$t('MyStyle.MyStyle')" />

    <div class="layout_body box">
      <section class="">
        <h6 class="allPage_tit">{{ $t('MyStyle.Size') }}</h6>
        <div class="inner pt14">
          <ul class="move_list m10">
            <li>
              <label class="txtinp">
                <input-number
                  v-model="form.mem_height"
                  :validate="false"
                  :maxLength="3"
                />
                <span class="txt"> {{ $t('MyStyle.HeightUnit') }}</span>
              </label>
            </li>
            <li>
              <label class="txtinp">
                <input-number
                  v-model="form.mem_weight"
                  :validate="false"
                  :maxLength="3"
                />
                <span class="txt"> {{ $t('MyStyle.WeightUnit') }}</span>
              </label>
            </li>
            <li v-if="form.age !== '0'">
              <label class="txtinp">
                <input-number
                  v-model="form.age"
                  :validate="true"
                  :maxLength="3"
                />
                <span class="txt"> {{ $t('MyStyle.AgeUnit') }}</span>
              </label>
            </li>
          </ul>
        </div>
      </section>
      <section class="border_top">
        <h6 class="allPage_tit">{{ $t('Join.JoinConcept') }}</h6>
        <div class="inner p1430">
          <div class="btn_list_2">
            <button
              v-for="item in concepts"
              :key="item.key"
              type="button"
              :class="form.concepts.indexOf(item.label) > -1 ? 'on' : ''"
              @click="handleClickConcept(item)"
            >
              {{ item.label }}
            </button>
          </div>
        </div>
      </section>
      <section class="border_top">
        <h6 class="allPage_tit btn_flex">
          {{ $t('Join.JoinStyle') }}
          <button
            type="button"
            class="fon12 btn_pop_open"
            @click="isShowMyStyle = true"
          >
            {{ $t('MyStyle.Change') }}
          </button>
        </h6>
        <div class="inner p1430 prod_swiper">
          <swiper v-if="form.styles.length > 0" :options="options">
            <swiper-slide v-for="item in form.styles" :key="item.key">
              <image-bg-src :src="getStyleImageUrl(item)" class="prod_img" />
            </swiper-slide>
          </swiper>

          <p v-else class="middle_txt" style="padding-bottom: 43px">
            {{ $t('MyStyle.AlertNoneStyle') }}
          </p>
        </div>
      </section>
      <div class="btm_foot">
        <button type="reset" class="font4" @click="$router.push('/mypage')">
          {{ $t('MyStyle.Cancel') }}
        </button>
        <button type="button" class="font4" @click="handleSubmit">
          {{ $t('MyStyle.UserInfoEdit') }}
        </button>
      </div>
      <article class="inner p1430">
        <div class="bggray_box">
          <ul>
            <li>
              {{ $t('MyStyle.StyleDescription1') }}
            </li>

            <li>
              {{ $t('MyStyle.StyleDescription2') }}
            </li>
          </ul>
        </div>
      </article>
    </div>
    <sild-footer />
    <mystyle-popup
      v-if="isShowMyStyle"
      :items="styles"
      :selected="form.styles"
      @close="isShowMyStyle = false"
      @confirm="handleConfirmMyStyle"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import AuthAPI from '../../apis/AuthAPI';
import ArrayUtils from '../../utils/ArrayUtils';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import MystylePopup from './popups/MystylePopup.vue';
import InputNumber from '../../components/Inputs/InputNumber/InputNumber.vue';
import useToast from '../../composables/dialogs/useToast';

export default {
  components: {
    BackTitleBasket,
    SildFooter,
    Swiper,
    SwiperSlide,
    ImageBgSrc,
    MystylePopup,
    InputNumber,
  },
  setup(_, context) {
    const { info, setUserInfo } = useUserInfo();

    const { addMsg } = useToast();

    const state = reactive({
      form: {
        mem_id: info?.mem_id,
        mem_height: info.mem_height,
        mem_weight: info.mem_weight,
        age: info.age,
        styles: info.mem_style ? info.mem_style.split(',') : [],
        concepts: info.mem_concept ? info.mem_concept.split(',') : [],
      },
      styles: [],
      concepts: [],
      isShowMyStyle: false,
    });
    // console.log('----');
    // console.log(info);
    // console.log('----');
    const loadDatas = async () => {
      const { data: styles } = await AuthAPI.getStyleItems();
      state.styles = styles.map((item) => ({
        key: item.style_id,
        label: item.style_value,
        image: item.img_url,
      }));

      const { data: concepts } = await AuthAPI.getConceptItems();
      state.concepts = concepts.map((item) => ({
        key: item.concept_id,
        label: item.concept_value,
        image: item.img_url,
      }));
      console.log(state.concepts);
    };

    loadDatas();

    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      ...toRefs(state),

      handleClickConcept(item) {
        const concepts = [...state.form.concepts];

        ArrayUtils.toggle(concepts, item.label);

        state.form.concepts = concepts;
      },

      getStyleImageUrl(label) {
        console.log(label, state.form.styles, state.form.concepts);
        const item = state.styles.find((item) => item.label === label);

        return item?.image ?? '';
      },

      handleConfirmMyStyle(items) {
        state.isShowMyStyle = false;
        state.form.styles = items.map((item) => item.label);
      },

      async handleSubmit() {
        const { data } = await AuthAPI.updateUserInfo2(state.form);

        if (data) {
          await setUserInfo(data);
          addMsg(context.root.$t('MyStyle.AlertStyleEdit'));
        }
      },
    };
  },
};
</script>

<style>
.swiper-slide a {
  width: 100%;
  display: block;
}
.prod_swiper .swiper-slide {
  position: relative;
  width: 43.6%;
  margin-right: 14px;
}
.prod_swiper .swiper-slide a div {
  position: relative;
}

.prod_swiper .prod_img {
  padding: 131% 0 0;
}
.prod_img button {
  bottom: 0;
  right: 0;
}

.txtinp input {
  position: relative;
  padding: 0 4px;
  min-width: 32px;
  border: 1px solid #efefef;
  height: 44px;
  width: 100%;
}
</style>
