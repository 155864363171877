<template>
  <div class="border_top">
    <ul class="move_list last_list border_bottom disflex">
      <li class="width50">
        <a class="btn_pop_open" @click="isShowUsage = true">
          {{ $t('Join.UsageTerm') }}</a
        >
      </li>

      <li class="width50">
        <a class="btn_pop_open" @click="isShowPrivate = true">
          {{ $t('Setting.TermPersonal') }}
        </a>
      </li>
    </ul>
    <div class="inner layout_bottom">
      <h5>{{ info.shop_title }}</h5>
      <ul class="en_info">
        <li>{{ $t('Layout.Ceo') }} : {{ info.ceo_name }}</li>
        <li>{{ $t('Layout.Office') }} : {{ info.office_addr }}</li>
        <li>{{ $t('Layout.Tel') }} : {{ info.tel }}</li>
        <li>{{ $t('Layout.Email') }} : {{ info.qna_email }}</li>
        <li>{{ $t('Layout.Biz_no') }} : {{ info.biz_no }}</li>
        <li>{{ $t('Layout.BusinessReport') }}</li>
      </ul>
      <p class="colora8 font2" v-html="$t('Layout.FooterText')"></p>
    </div>
    <usage-popup v-if="isShowUsage" @close="isShowUsage = false" />
    <private-popup v-if="isShowPrivate" @close="isShowPrivate = false" />
  </div>
</template>

<script>
import useShopInfo from '@/composables/shop/useShopInfo';
import UsagePopup from '@/views/settings/popups/UsagePopup.vue';
import PrivatePopup from '@/views/settings/popups/PrivatePopup.vue';
import { reactive, toRefs } from 'vue-demi';

export default {
  components: { UsagePopup, PrivatePopup },
  setup() {
    const { info } = useShopInfo();

    const state = reactive({
      isShowUsage: false,
      isShowPrivate: false,
    });

    return {
      ...toRefs(state),
      info,
    };
  },
};
</script>

<style></style>
