<template>
  <div class="wrap">
    <back-title-basket :title="$t('Review.MyReview')" />

    <div class="layout_body box_p60 re_layout">
      <div class="list_layout info_tit_box">
        <!--리뷰 혜택 안내 시작-->
        <div>
          <h6 class="allPage_tit">{{ $t('Review.ReviewBenefit') }}</h6>
          <div class="inner">
            <ul class="order_tip cancel_tip">
              <li>
                {{ $t('Review.ReviewDescription1') }}
              </li>
              <li>
                {{ $t('Review.ReviewDescription2') }}
              </li>
              <li>
                {{ $t('Review.ReviewDescription3') }}
              </li>

              <li>
                <div class="relative mb10">
                  <input
                    v-model="params.keyword"
                    class="gray_search"
                    type="search"
                    :placeholder="$t('Review.AlertReview')"
                  />
                  <button type="button" class="btnClear" @click="loadDatas" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--리뷰 혜택 안내 끝-->
        <!--리뷰 쓰기 시작-->
        <review-wriite-item
          v-for="item in writeItems"
          :key="item.cde_id"
          :item="item"
        />
        <my-review-item
          v-for="item in items"
          :key="item.cre_id"
          :item="item"
          :cde_id="item.cde_id"
          :cit_id="item.cit_id"
          :cre_id="item.cre_id"
          @updateReview="loadDatas"
          @deleteReview="handleDelete"
        ></my-review-item>
        <button
          v-show="hasMore"
          class="more"
          type="button"
          @click="handleClickMore"
        >
          {{ $t('Ect.More') }}
        </button>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import ProductAPI from '../../../apis/ProductAPI';
import SildFooter from '../../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import MyReviewItem from '../../../components/ui/MyReviewItem/MyReviewItem.vue';
import ReviewWriiteItem from '../../../components/ui/ReviewWriiteItem/ReviewWriiteItem.vue';

import useToast from '@Composables/dialogs/useToast';
import useConfirm from '@Composables/dialogs/useConfirm';

export default {
  components: { BackTitleBasket, SildFooter, MyReviewItem, ReviewWriiteItem },
  setup(_, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const state = reactive({
      items: [],
      write: null,
      hasMore: true,
      total: 0,
      writeItems: [
        // {
        //   cit_id: 60,
        //   cde_id: 21581,
        // },
        // {
        //   cit_id: 61,
        //   cde_id: 21582,
        // },
      ],
    });

    const params = {
      mem_id: info?.mem_id,
      keyword: '',
      page: 0,
      limit: 10,
    };

    const loadDatas = async () => {
      console.log('#####실행');
      const { data } = await ProductAPI.getMyReviews(params);
      state.total = data.result_data.cntRow;
      state.items = [...state.items, ...data.result_data.data];
      console.log(state.total);

      state.hasMore = state.total >= params.limit;
    };
    loadDatas();
    // const handleEditReview = (data) => {
    //   state.write = data;
    //   console.log('data!!!!!!!!!!!!!', data);
    //   loadDatas();
    // };

    const deleteReview = async (cre_id) => {
      const { data } = await ProductAPI.deleteReviewDetail({
        cre_id,
      });

      state.items = state.items.filter((item) => item.cre_id !== cre_id);
      if (data.result) {
        addMsg(context.root.$t('Button.AlertDeleteComplete'));
      }
      console.log('삭제!@!!!!');
      console.log(state.items);
    };

    return {
      params,
      loadDatas,
      // handleEditReview,
      ...toRefs(state),
      handleDelete(item) {
        showConfirm(context.root.$t('Basket.AlertDelete'), () => {
          deleteReview(item.cre_id);
          loadDatas();
        });
      },
      handleClickMore() {
        params.page++;
        loadDatas();
      },
    };
  },
};
</script>

<style scoped>
.more {
  border: 1px solid #ccc;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
</style>
