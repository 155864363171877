import { axios } from '@/plugins/axios';

export default {
  getPointList(params) {
    return axios.post('point/getPointList', params);
  },

  getColorBanner(color) {
    return axios.post('banner/getColorBanner', { color });
  },
};
