<template>
  <down-full-up-popup
    :title="$t('Setting.OpenLicense')"
    class="license-popup"
    @close="$emit('close')"
  >
    <div slot="body">
      <ul>
        <li v-for="item in licenses" :key="item.name" class="license-item">
          <h2>{{ item.name }}</h2>
          <div class="info">
            <a :href="item.link.replace('git+', '')" class="tit">
              {{ item.link.replace('git+', '') }}
            </a>
            <p>{{ item.licenseType }} License</p>
          </div>
        </li>
      </ul>
    </div>
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import licenses from '@/assets/json/licenses.json';
export default {
  components: { DownFullUpPopup },
  setup() {
    return {
      licenses,
    };
  },
};
</script>

<style>
.license-item {
  padding-bottom: 10px;
}
.license-item h2 {
  font-size: 14px;
  font-weight: bold;
}
.license-item .info {
  padding-left: 10px;
}

.license-item .info * {
  font-size: 12px;
}

.license-item .info a {
  color: blue;
  text-decoration: underline;
}

.license-popup .pop_body * {
  font-size: 0.85rem;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
</style>
