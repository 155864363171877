<template>
  <div class="border_top">
    <div class="review_con ver2 disflex">
      <div
        class="review_pro"
        style="background-image: url(../images/design/cloth.png)"
      ></div>
      <div class="detail_pro right">
        <p class="font2 ellipsis">
          상품명 출력 상품명 출력 상품명 출력상품명 출력 상 품명 출력 상품명
          출력 상품명 출력 상품명 출력
        </p>
        <span>색상 블루 / 사이즈 M</span>
        <span>배송일자 2022-10-01</span>
      </div>
    </div>
    <div class="inner">
      <div class="mtb10">
        <router-link
          :to="`/product/review-write/${item.cit_id}/${item.cde_id}`"
          class="line_btnG"
          style="text-align: center; display: block"
        >
          리뷰쓰기
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
};
</script>

<style></style>
