<template>
  <down-full-up-popup
    :title="$t('Join.PersonalTermTitle')"
    @close="$emit('close')"
  >
    <div slot="body" v-html="text" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import useShopInfo from '@Composables/shop/useShopInfo';

export default {
  components: { DownFullUpPopup },
  setup() {
    const { info } = useShopInfo();
    const text = info.private5.replace('{{업체명}}', '업체명!!!!!!!!!!!!');
    return {
      info,
      text,
    };
  },
};
</script>
<style></style>
