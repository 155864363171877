<template>
  <div
    :style="`background-image:url(${require(`@/assets/images/design/${fileName}`)})`"
    class="image-block"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: ['fileName'],
};
</script>

<style>
.image-block {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
</style>
