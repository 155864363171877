<template>
  <div class="border_top">
    <h6 class="allPage_tit">{{ item.ins_dtm.substr(0, 10) }}</h6>
    <div class="inner p04 table_wrap ver2">
      <div>
        <table class="table_box">
          <colgroup>
            <col style="width: 27%" />
            <col style="width: 73%" />
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('Point.Date') }}</th>
              <td>{{ item.ins_dtm.substr(0, 10) }}</td>
            </tr>
            <tr>
              <th>{{ $t('Point.DetailList') }}</th>
              <td>{{ item.point_type }}</td>
            </tr>
            <tr>
              <th>{{ $t('Point.Accumulated') }}</th>
              <td>
                {{
                  item.point_dir === 'plus'
                    ? item.point_val.numberFormat() + 'P'
                    : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('Point.Expiration') }}</th>
              <td>{{ item.exp_dtm ? item.exp_dtm : '-' }}</td>
            </tr>
            <tr>
              <th>{{ $t('Point.AmountUsed') }}</th>
              <td>
                {{
                  item.point_dir === 'minus'
                    ? item.point_val.numberFormat()
                    : '-'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
};
</script>

<style></style>
