<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.InfoEdit')" />
    <div class="layout_body box_p60">
      <section>
        <h6 class="allPage_tit">{{ $t('InfoEdit.UserInfo') }}</h6>
        <div class="joinForm_box modify_wrap">
          <ul class="wrap_flex userinfo">
            <li class="basic_txt">
              <label>아이디</label>
              <input
                type="text"
                :value="form.mem_userid"
                class="btn_group"
                readonly
              />
            </li>
            <li class="basic_txt">
              <label>{{ $t('Join.UserName') }}</label>
              <input
                type="text"
                :value="form.mem_username"
                class="btn_group"
                readonly
              />
            </li>
            <li>
              <label for="mem_password">{{ $t('Join.Password') }}</label>
              <input
                id="mem_password"
                v-model="form.mem_password"
                type="password"
                name="mem_password"
                maxLength="15"
                :placeholder="$t('Join.EnterPw')"
                @input="handleInputPw"
                @focusout="handleFocusOutName"
              />
              <p v-show="error.mem_password" class="error">
                {{ $t('Join.ErrorPwMixed') }}
              </p>

              <p v-show="error.sameNumberPw" class="error">
                {{ $t('Join.ErrorPwSequence') }}
              </p>
              <p v-show="error.isShoNoticeMem_pw" class="error">
                {{ $t('Join.ErrorPwWord') }}
              </p>
            </li>
            <li>
              <label for="userPwchk">{{ $t('Join.UserPwAgain') }}</label>
              <input
                id="userPwchk"
                v-model="form.pwCk"
                type="password"
                name="pwCk"
                maxLength="15"
                :placeholder="$t('Join.EnterPwAgain')"
                @input="handleInputPwChk"
                @focusout="handleFocusOutName"
              />
              <p v-show="error.pwCk" class="error">
                {{ $t('Join.ErrorPwSame') }}
              </p>
            </li>
            <li class="btn_inp">
              <label for="phoneNum">{{ $t('Join.PhoneNumber') }}</label>
              <input-number
                id="mem_phone"
                v-model="form.mem_phone"
                name="mem_phone"
                :maxLength="11"
                :placeholder="$t('Join.EnterHyphen')"
                class="btn_group"
                readonly="readonly"
                @click="handleClickPhone"
              />
              <button class="black_btn" @click="handleClickPhone">
                {{ $t('InfoEdit.Certification') }}
              </button>
              <p v-show="error.mem_phone" class="error">
                {{ $t('InfoEdit.InputPhone') }}
              </p>
              <p class="disflex">
                <input
                  id="event_ok"
                  v-model="form.is_rcv_sms"
                  type="checkbox"
                  class="normal"
                /><label for="event_ok" class="font2 chkbox cheked"
                  >{{ $t('Join.Marketing') }}
                </label>
              </p>
            </li>
            <li>
              <label for="mem_email">{{ $t('Join.UserEmail') }}</label>
              <input
                id="mem_email"
                v-model="form.mem_email"
                type="text"
                name="mem_email"
                maxLength="40"
                :placeholder="$t('Join.EnterEmail')"
                @focusout="handleFocusOutName"
              />
              <p v-show="error.mem_email" class="error">
                {{ $t('Join.ErrorEmail') }}
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section class="border_top">
        <h6 class="allPage_tit">{{ $t('InfoEdit.EasyLogin') }}</h6>
        <div class="inner p04">
          <div class="move_list type2 swich_wrap clear">
            <p class="btn_flex">
              <span>{{ $t('InfoEdit.LoginKakao') }}</span>
              <switch-box
                :value="form.sns_use_kakao_yn === 'y'"
                :disabled="false"
              />
            </p>

            <p class="btn_flex">
              <span>{{ $t('InfoEdit.LoginNaver') }}</span>
              <switch-box
                :value="form.sns_use_naver_yn === 'y'"
                :disabled="false"
              />
            </p>

            <p class="btn_flex">
              <span>{{ $t('InfoEdit.LoginGoogle') }}</span>
              <switch-box
                :value="form.sns_use_google_yn === 'y'"
                :disabled="false"
              />
            </p>

            <p class="btn_flex">
              <span>{{ $t('InfoEdit.LoginApple') }}</span>
              <switch-box
                :value="form.sns_use_apple_yn === 'y'"
                :disabled="false"
              />
            </p>
          </div>
        </div>
        <div class="btm_foot">
          <button type="button" @click="$router.push('/mypage')">
            {{ $t('MyStyle.Cancel') }}
          </button>
          <button type="button" @click="handleClickSubmit">
            {{ $t('MyStyle.UserInfoEdit') }}
          </button>
        </div>
      </section>
      <article class="inner p1430">
        <div class="bggray_box">
          <ul>
            <li>
              {{ $t('MyStyle.StyleDescription1') }}
            </li>
            <li>
              {{ $t('MyStyle.StyleDescription2') }}
            </li>
          </ul>
        </div>
      </article>
    </div>
    <phone-form
      v-if="isShowPhoneForm"
      :datas="datas"
      @close="isShowPhoneForm = false"
      @complate="handleComplateAuth"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import AuthAPI from '@/apis/AuthAPI';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useAlert from '../../composables/dialogs/useAlert';
import useUserInfo from '../../composables/user/useUserInfo';
import InputNumber from '../../components/Inputs/InputNumber/InputNumber.vue';
import SwitchBox from '../../components/Inputs/SwitchBox/SwitchBox.vue';
import PhoneForm from '../auth/PhoneForm.vue';

export default {
  components: { BackTitleBasket, InputNumber, SwitchBox, PhoneForm },
  setup(_, context) {
    const { info, setUserInfo } = useUserInfo();

    const { showAlert } = useAlert();
    console.log('회원정보!!!!!!!!!!!!!', info);
    const state = reactive({
      error: {},
      form: {
        ...info,
        sns_auth_apple: !!info.sns_auth_apple,
        sns_auth_kakao: !!info.sns_auth_kakao,
        sns_auth_naver: !!info.sns_auth_naver,
        sns_auth_google: !!info.sns_auth_google,
        is_rcv_sms: info.is_rcv_sms === 'y' ? true : false,
        mem_password: '',
        pwCk: '',
      },
      is_rcv_sms: false,
      datas: null,
      isShowPhoneForm: false,
      isShoNoticeMem_pw: false,
    });
    console.log(state.form.is_rcv_sms);
    console.log(info.is_rcv_sms);
    const validateObj = {
      mem_phone: /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/,
      mem_email:
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,

      mem_password(value) {
        let reg = /(?=.*?[a-z])(?=.*?[A-Z])/;
        let reg2 = /[~!@#$%^&*()_+|<>?:{}]/;

        const error = {
          mem_password: false,
          sameNumberPw: false,
          isShoNoticeMem_pw: false,
        };

        if (value === '') {
          error.mem_password = false;
        } else {
          if (!reg.test(value) || !reg2.test(value) || value.length < 6) {
            error.mem_password = true;
          } else {
            error.sameNumberPw = checkSameNumber(value);
          }
          // if(reg2.test(value)){
          //   error.isShoNoticeMem_pw = true;
          // } else {
          //   error.isShoNoticeMem_pw = false;
          //
          // }
        }
        return error;
      },
      pwCk(value) {
        const error = {
          pwCk: false,
        };

        error.pwCk = value !== state.form.mem_password;
        return error;
      },
    };

    const validate = () => {
      validateInputValues();

      let isSubmit = true;

      Object.keys(state.error).forEach((key) => {
        if (isSubmit) isSubmit = !state.error[key];
      });

      return isSubmit;
    };

    const validateInputValues = () => {
      let error = {};

      Object.keys(validateObj).forEach((key) => {
        error = { ...error, ...validateByName(key) };
      });

      state.error = error;
    };

    const validateByName = (name) => {
      if (typeof validateObj[name] === 'object') {
        const error = {};

        error[name] = !validateObj[name].test(state.form[name]);

        return error;
      }

      return validateObj[name](state.form[name]);
    };

    return {
      ...toRefs(state),
      handleInputPw() {
        state.form.mem_password = state.form.mem_password.toString();
      },
      handleInputPwChk() {
        state.form.pwCk = state.form.pwCk.toString();
      },
      async handleFocusOutName(e) {
        const error = validateByName(e.target.name);

        state.error = { ...state.error, ...error };
      },

      async handleClickSubmit() {
        if (!validate()) {
          return showAlert('필수항목 입력 바랍니다.');
        }

        const params = {
          ...state.form,
          is_rcv_sms: state.form.is_rcv_sms ? 'y' : 'n',
          sns_use_naver_yn: state.form.sns_use_naver_yn ? 'y' : 'n',
          sns_use_kakao_yn: state.form.sns_use_kakao_yn ? 'y' : 'n',
          sns_use_apple_yn: state.form.sns_use_apple_yn ? 'y' : 'n',
          sns_use_google_yn: state.form.sns_use_google_yn ? 'y' : 'n',
        };
        console.log('데이타 :', params);

        const { data } = await AuthAPI.updateMemberInfo(params);
        console.log('변경된 유저데이타!!!!!!!!!!', data.result_data);
        await setUserInfo(data.result_data);

        if (data.result) {
          showAlert('수정되었습니다.', () => {
            context.root.$router.push('/mypage');
          });
        }
      },

      handleClickSns() {
        // const name = e.target.name;
        //sns 계정이 연동되지 않은 경우 disabled
        // const isNotInterlock = state.form[`sns_auth_${name}`] === null;
        // if (isNotInterlock) {
        //   e.target.checked = !e.target.checked;
        // }
      },
      async handleClickPhone() {
        // 팝업이 열려있는 경우 다시 열지않음
        if (state.isShowPhoneForm) return;

        const { data } = await AuthAPI.getPhoneInfo({
          code: process.env.VUE_APP_URLCODE_UPDATE_INFO,
        });

        state.datas = data;

        state.isShowPhoneForm = true;
      },
      async handleComplateAuth(data) {
        state.form.mem_username = data.name;
        state.form.mem_phone = data.phone_no;

        // const { data: newInfo } = await AuthAPI.authUserInfo(form);

        // localStorage.setItem('mem_id', newinfo?.mem_id);

        // await setUserInfo(newInfo);

        // context.root.$router.replace('/');
      },
    };
  },
};

const sameNumber = [
  '111',
  '222',
  '333',
  '444',
  '555',
  '666',
  '777',
  '888',
  '999',
  '012',
  '123',
  '234',
  '345',
  '456',
  '567',
  '678',
  '789',
];

const checkSameNumber = (value) => {
  let isSameNumber = false;

  sameNumber.forEach((str) => {
    if (isSameNumber === false) {
      isSameNumber = value.indexOf(str) > -1;
    }
  });

  return isSameNumber;
};
</script>

<style>
#mem_password::placeholder {
  font-size: 2px;
}
</style>
