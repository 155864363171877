<template>
  <down-full-up-popup
    :title="$t('Setting.TermPersonal')"
    class="private-popup"
    @close="$emit('close')"
  >
    <div slot="body" v-html="info.private" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import useShopInfo from '@/composables/shop/useShopInfo';

export default {
  components: { DownFullUpPopup },
  setup() {
    const { info } = useShopInfo();

    return {
      info,
    };
  },
};
</script>

<style>
.private-popup .pop_body * {
  font-size: 0.85rem;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
</style>
