<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.Point')" />

    <div class="layout_body box_p60">
      <div class="list_layout info_tit_box point_layout">
        <div class="inner p04">
          <div class="info_tit point">
            <p
              v-html="
                $t('Point.Subject', {
                  UserPoint: info.mem_point.numberFormat(),
                })
              "
            ></p>
          </div>
        </div>

        <!-- 포인트 내역 -->
        <point-list-item
          v-for="item in items"
          :key="item.mpl_id"
          :item="item"
        />

        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Point.PointTip') }}</h6>
          <div class="inner ptb14">
            <ul class="order_tip cancel_tip">
              <li>
                {{ $t('Point.PointDescription1') }}
              </li>
              <li>{{ $t('Point.PointDescription2') }}</li>
              <li v-html="$t('Point.PointDescription3')"></li>
              <li v-html="$t('Point.PointDescription4')"></li>
              <li>{{ $t('Point.PointDescription5') }}</li>
              <li>{{ $t('Point.PointDescription6') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import PointListItem from '../../components/ui/PointListItem/PointListItem.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import PointAPI from '../../apis/PointAPI';
import { reactive, toRefs } from 'vue-demi';

export default {
  components: { BackTitleBasket, SildFooter, PointListItem },
  setup() {
    const { info } = useUserInfo();

    const state = reactive({
      items: [],
    });

    const loadDatas = async () => {
      const { data } = await PointAPI.getPointList({
        mem_id: info?.mem_id,
      });

      state.items = data.result_data;
    };

    loadDatas();

    return {
      ...toRefs(state),
      info,
    };
  },
};
</script>

<style></style>
