<template>
  <form action="#" method="post" name="reqKMCISForm">
    <input :value="datas.tr_cert" name="tr_cert" type="hidden" />
    <input :value="datas.tr_url" name="tr_url" type="hidden" />
    <input :value="datas.tr_add" name="tr_add" type="hidden" />
  </form>
</template>

<script>
import { onMounted } from 'vue-demi';
import AuthAPI from '../../apis/AuthAPI';

export default {
  props: ['datas'],
  setup(props, context) {
    console.log('-> datas.tr_cert', props.datas.tr_cert);
    console.log('-> datas.tr_url', props.datas.tr_url);
    console.log('-> datas.tr_add', props.datas.tr_add);

    window.name = 'kmcis_web_sample';

    let KMCIS_window;

    const onClosePopup = async () => {
      const { data } = await AuthAPI.getMemberAuthInfo({
        cert_num: props.datas.certNum,
        // cert_num: '20220723164045679005',
      });
      console.log('@@@@@');
      console.log(data);
      console.log('@@@@@');

      if (data === null) {
        context.emit('close');
      } else {
        if (data) {
          context.emit('complate', data);
        } else {
          context.emit('close');
        }
      }
    };

    const isCloseWindow = () => {
      if (KMCIS_window.window) {
        setTimeout(isCloseWindow, 500);
      } else {
        onClosePopup();
      }
    };

    const isOpenWindow = () => {
      if (!KMCIS_window) {
        setTimeout(isOpenWindow, 500);
      } else {
        isCloseWindow();
      }
    };

    onMounted(() => {
      // alert(`${props.datas.tr_url}, ${props.datas.tr_add}`);

      var UserAgent = navigator.userAgent;
      /* 모바일 접근 체크*/
      // 모바일일 경우 (변동사항 있을경우 추가 필요)
      if (
        UserAgent.match(
          /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
        ) != null ||
        UserAgent.match(/LG|SAMSUNG|Samsung/) != null
      ) {
        document.reqKMCISForm.target = '';
      }

      // 모바일이 아닐 경우
      else {
        KMCIS_window = window.open(
          '',
          'KMCISWindow',
          'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250'
        );

        if (KMCIS_window == null) {
          alert(
            ' ※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n    화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.'
          );
        }

        document.reqKMCISForm.target = 'KMCISWindow';
      }

      document.reqKMCISForm.action =
        'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';
      document.reqKMCISForm.submit();

      isOpenWindow();
    });
  },
};
</script>

<style></style>
