<template>
  <div class="wrap">
    <back-title-basket :title="$t('CustomerService.Notice')" />
    <div class="layout_body">
      <div class="search_box">
        <input
          v-model="keyword"
          type="search"
          :placeholder="$t('CustomerService.NoticeSearch')"
        />
        <button type="summit" class="serch" @click="handleClickSearch"></button>
      </div>
      <div>
        <section class="tab_content">
          <div class="inner p04">
            <div class="drop_down_wrap">
              <ul class="single_content">
                <dropdown-notice-item
                  v-for="item in items"
                  :key="item.id"
                  :item="item"
                  :is-open="selectedId === item.id"
                />
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
    <sild-footer :isToggleNav="true" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue-demi';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import DropdownNoticeItem from '../../components/ui/DropdownNoticeItem/DropdownNoticeItem.vue';
import CommonAPI from '@/apis/CommonAPI';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';

export default {
  components: { BackTitleBasket, DropdownNoticeItem, SildFooter },

  setup(_, context) {
    const state = reactive({
      selectedId: null,
      items: [],
      keyword: '',
    });

    const loadDatas = async () => {
      const { data } = await CommonAPI.getNoticeList({
        keyword: state.keyword,
      });

      const items = data.result_data.map(item => ({
        id: item.cbn_id,
        cqa_title: item.cbn_title,
        date: item.upd_dtm.substr(0, 10),
        content: item.cbn_content,
        gray_content: item.cbn_content,
      }));

      state.items = [...state.items, ...items];
    };

    onMounted(async () => {
      await loadDatas();
      state.selectedId = context.root.$route.query?.id;
    });

    return {
      ...toRefs(state),
      handleClickSearch() {
        state.items = [];

        loadDatas();
      },
    };
  },
};
</script>

<style></style>
