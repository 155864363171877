<template>
  <li class="coupon_box disflex">
    <div class="coupon_content disflex">
      <h4>
        <slot name="header">
          {{ item.ccp_name }}
        </slot>
      </h4>
      <div style="width: 100%">
        <div class="center-content">
          <slot name="center-content">
            <div v-if="item.coupon_type !== 'delivery'">
              {{
                item.price_type === '1'
                  ? ` ${item.ccp_val}%`
                  : `₩ ${item.ccp_val.numberFormat()}`
              }}
              {{ $t('Button.DiscountCoupon') }}
            </div>
            <div v-else>무료배송쿠폰</div>
          </slot>
        </div>
        <p class="center-content">
          <slot name="desc">
            {{ item.ccp_desc }}
          </slot>
        </p>
        <p
          class="limited_amount"
          v-if="item.min_val !== '0' && item.coupon_type !== 'delivery'"
        >
          최소구매금액 : ₩
          {{ item.min_val === '0' ? '0' : item.min_val.numberFormat() }}<br />
        </p>

        <p
          class="limited_amount"
          v-if="item.max_val !== '0' && item.coupon_type !== 'delivery'"
        >
          ₩
          {{ item.max_val === '0' ? '0' : item.max_val.numberFormat() }} 까지
          할인
        </p>
        <p class="center-content">
          <slot name="desc">
            유효기간 {{ item.use_start_date }} ~ {{ item.use_end_date }}
          </slot>
        </p>
      </div>
    </div>

    <button
      :class="`coupon_down ${isOn ? '' : 'off'}`"
      @click="handleClickDownload"
    />
  </li>
</template>

<script>
import CouponAPI from '../../../apis/CouponAPI';
import useToast from '../../../composables/dialogs/useToast';
import useUserInfo from '../../../composables/user/useUserInfo';

export default {
  props: {
    item: { default: {} },
    isOn: { default: true },
  },
  setup(props, context) {
    const { addMsg } = useToast();
    const { info } = useUserInfo();

    const download = async () => {
      CouponAPI.downloadCoupon({
        mem_id: info?.mem_id,
        ccp_id: props.item.ccp_id,
      });
    };

    return {
      handleClickDownload() {
        if (props.isOn === false) return;

        context.emit('download');

        download();

        addMsg(context.root.$t('Button.AlertDown'));
      },
    };
  },
};
</script>

<style scoped>
.coupon_box {
  position: relative;
  margin-bottom: 14px;
  max-height: 300px;
}
.coupon_content {
  /*margin-top: -1px;
  margin-left: 5px;*/
  border-radius: 10px 0 0 10px;
}
.coupon_down {
  top: 0;
  height: 100%;
}
.coupon_box h4,
.coupon_box .center-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.coupon_content p {
  font-size: 12px;
  color: #a8a8a8;
}
</style>
