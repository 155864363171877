<template>
  <div class="wrap faq">
    <back-title-basket title="FAQ" />

    <div class="layout_body">
      <div class="search_box">
        <input
          v-model="keyword"
          type="search"
          :placeholder="$t('CustomerService.NoticeSearch')"
        />
        <button type="summit" class="serch" @click="handleClickSearch"></button>
      </div>
      <swiper class="header_tab disflex tab_page" :options="options">
        <swiper-slide
          v-for="item in categoris"
          :key="item.key"
          :class="type === item.key ? 'on' : ''"
        >
          <a @click="handleClickTab(item.key)">{{ item.label }}</a>
        </swiper-slide>
      </swiper>
      <section class="tab_content">
        <div class="inner p04">
          <div class="drop_down_wrap">
            <ul class="single_content">
              <dropdown-notice-item
                v-for="item in items"
                :key="item.id"
                :item="item"
              />
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import DropdownNoticeItem from '../../components/ui/DropdownNoticeItem/DropdownNoticeItem.vue';
import CommonAPI from '@/apis/CommonAPI';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: { BackTitleBasket, DropdownNoticeItem, Swiper, SwiperSlide },

  setup() {
    const state = reactive({
      items: [],
      categoris: [],
      keyword: '',
      type: undefined,
    });

    const loadBoardCategory = async () => {
      const { data } = await CommonAPI.getBoardCategory();

      state.categoris = [
        { key: undefined, label: '전체' },
        ...data.result_data.map(item => ({
          key: item.cbc_id,
          label: item.cbc_name,
        })),
      ];
    };

    loadBoardCategory();

    const loadDatas = async () => {
      const { data } = await CommonAPI.getFaqList({
        keyword: state.keyword,
        cbc_id: state.type,
      });

      const items = data.result_data.map(item => ({
        id: item.faq_id,
        cqa_title: item.faq_title,
        date: item.upd_dtm.substr(0, 10),
        content: item.faq_content,
        gray_content: item.faq_content,
      }));

      state.items = [...state.items, ...items];
    };

    loadDatas();

    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      ...toRefs(state),
      handleClickSearch() {
        state.items = [];

        loadDatas();
      },

      handleClickTab(key) {
        state.items = [];
        state.type = key;

        loadDatas();
      },
    };
  },
};
</script>

<style></style>
