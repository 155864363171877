<template>
  <label class="switch">
    <input
      type="checkbox"
      :value="value"
      :checked="value"
      :disabled="disabled"
      @change="$emit('change', checkedChageEvent($event.target.checked))"
    />
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  props: {
    value: Boolean,
    disabled: { default: false },
  },
  setup() {
    const checkedChageEvent = check => {
      return check;
    };
    return {
      checkedChageEvent,
    };
  },
};
</script>

<style></style>
