<template>
  <down-full-up-popup
    :title="$t('MyStyle.MyPreferStyle')"
    class="my-style-popup"
    @close="$emit('close')"
  >
    <template slot="body" style="padding: 0">
      <div>
        <p class="font4 fontW500" v-html="$t('MyStyle.AlertSelect')"></p>
        <span class="font2 pb14 disblock">
          {{ $t('MyStyle.TheMore') }}
        </span>

        <article class="conp_box prefer">
          <div class="prod_swiper noswiper_prod p0">
            <ul>
              <li v-for="(item, idx) in items" :key="item.key">
                <toggle-image
                  :key="item.key"
                  v-model="styles[idx]"
                  :item="item"
                />
              </li>
            </ul>
          </div>
        </article>
      </div>
    </template>
    <template slot="footer">
      <button
        type="button"
        :class="`black_btn width100 ${
          styles.filter(val => val).length > 2 ? '' : 'gray'
        }`"
        @click="onClickConfirm"
      >
        {{ $t('Join.Confirm') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import ToggleImage from '../../../components/Inputs/ToggleImage/ToggleImage.vue';
import useToast from '../../../composables/dialogs/useToast';

export default {
  props: ['items', 'selected'],
  components: { DownFullUpPopup, ToggleImage },
  setup({ items, selected }, context) {
    const { addMsg } = useToast();

    const state = reactive({
      styles: items.map(item => selected.indexOf(item.label) > -1),
      isShowETC: false,
    });

    return {
      ...toRefs(state),

      handleClickETC() {
        state.isShowETC = true;
      },

      onClickConfirm() {
        const selected = [];

        state.styles.forEach((val, idx) => {
          if (val) selected.push(items[idx]);
        });

        if (selected.length < 3) {
          addMsg(context.root.$t('MyStyle.Alert3Over'));
          return;
        }

        context.emit('confirm', selected);
      },
    };
  },
};
</script>

<style>
.my-style-popup .pop_body {
  padding-bottom: 0;
}
</style>
