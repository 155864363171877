<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.Withdraw')" />

    <div class="layout_body">
      <div class="inner">
        <h6 class="pt10 fontW500 mb6">
          {{ $t('Withdraw.WithdrawDescription1') }}
        </h6>
        <p class="pb10 font2" v-html="$t('Withdraw.WithdrawDescription2')"></p>
      </div>
      <div class="border_top">
        <h6 class="allPage_tit">{{ $t('Withdraw.ChkBoxTitle') }}</h6>
        <div class="inner p04">
          <ul class="wrap_flex basic_info">
            <li
              v-for="(item, idx) in reasons"
              :key="item"
              class="disflex input_chk_box"
            >
              <input
                :id="`resign_${idx}`"
                v-model="leave_reason_codes[idx]"
                type="checkbox"
              />
              <label :for="`resign_${idx}`" class="color37 font2">
                {{ item }}
              </label>
            </li>
            <li class="ect_reason disnone">
              <label for="cancel9" class="basic_tit"></label>
              <input
                id="cancel9"
                type="text"
                class="deep_type"
                :placeholder="$t('Withdraw.ReturnReason')"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="border_top mt30">
        <h6 class="allPage_tit">{{ $t('Withdraw.TextAreaTitle') }}</h6>
        <div class="inner">
          <textarea
            v-model="leave_reason_msg"
            class="bogray h120"
            minlength="30"
            :placeholder="$t('Withdraw.Text30Over')"
          ></textarea>
        </div>
      </div>
      <div class="inner p04 mb20">
        <div class="bggray_box">
          <ul>
            <li>{{ $t('Withdraw.WithdrawDescription3') }}</li>
            <li>{{ $t('Withdraw.WithdrawDescription4') }}</li>
            <li>{{ $t('Withdraw.WithdrawDescription5') }}</li>
            <li>{{ $t('Withdraw.WithdrawDescription6') }}</li>
            <li>{{ $t('Withdraw.WithdrawDescription7') }}</li>
            <li>{{ $t('Withdraw.WithdrawDescription8') }}</li>
          </ul>
        </div>
        <input
          v-model="mem_password"
          type="password"
          class="deep_type mt20"
          :placeholder="$t('Withdraw.WithdrawPw')"
        />
      </div>
      <div class="disflex">
        <button
          type="button"
          class="black_btn gray_btn font4"
          @click="$router.push('/mypage')"
        >
          {{ $t('MyStyle.Cancel') }}
        </button>
        <button
          type="button"
          class="black_btn font4 btn_pop_open"
          data-popup="resign_reconfirm_pop"
          @click="handleSubmit"
        >
          {{ $t('Withdraw.WithdrawButton') }}
        </button>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import AuthAPI from '../../apis/AuthAPI';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useConfirm from '../../composables/dialogs/useConfirm';
import useUserInfo from '../../composables/user/useUserInfo';
import useAlert from '../../composables/dialogs/useAlert';

export default {
  components: { SildFooter, BackTitleBasket },
  setup(_, context) {
    const { showConfirm } = useConfirm();
    const { showAlert } = useAlert();
    const { info, clearUserInfo } = useUserInfo();

    const state = reactive({
      mem_id: info?.mem_id,
      leave_reason_codes: [],
      leave_reason_msg: '',
      mem_password: '',
    });
    const validate = () => {
      if (!state.mem_password) {
        showAlert('비밀번호를 입력해주세요.');
        return false;
      } else if (state.leave_reason_msg.length < 30) {
        showAlert('30자 이상 입력해주세요.');
        return false;
      }

      return true;
    };

    const reasons = [
      context.root.$t('Withdraw.DeliveryComplaint'),
      context.root.$t('Withdraw.ProductComplaint'),
      context.root.$t('Withdraw.ReturnComplaint'),
      context.root.$t('Withdraw.BenefitComplaint'),
      context.root.$t('Withdraw.UsabilityComplaint'),
      context.root.$t('Withdraw.InformationExtrusion'),
      context.root.$t('Withdraw.NotUsed'),
      context.root.$t('Withdraw.Dissatisfaction'),
      context.root.$t('Withdraw.EctReason'),
    ];

    const withdrawal = async () => {
      if (validate()) {
        const leave_reason_code = state.leave_reason_codes
          .map((checked, idx) => (checked ? reasons[idx] : null))
          .filter((value) => value !== null);

        const params = {
          ...state,
          leave_reason_code: leave_reason_code.join(','),
        };

        const { data } = await AuthAPI.withdrawal(params);

        if (data.result_data) {
          showAlert(context.root.$t('Withdraw.Goodbye'));

          clearUserInfo();

          context.root.$router.replace('/');
        } else {
          showAlert(data.result_msg);
        }
      }
    };

    return {
      reasons,
      ...toRefs(state),

      handleSubmit() {
        showConfirm(context.root.$t('Withdraw.AlertGoodbye'), withdrawal);
      },
    };
  },
};
</script>

<style></style>
