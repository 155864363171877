<template>
  <div class="wrap">
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <div class="left_header">
            <router-link to="/mypage" class="menu">
              {{ $t('Mypage.Mypage') }}
            </router-link>
            <a class="menu on">{{ $t('Mypage.Digital') }}</a>
          </div>
          <div class="right_header">
            <basket-button />
          </div>
        </nav>
      </div>
    </header>
    <div class="layout_body">
      <div class="list_layout">
        <!--디지털 보증서가 없을때 -->
        <p class="alone_txt" style="display: none">디지털보증서가 없습니다.</p>
        <div class="inner">
          <div class="relative mb10">
            <input
              v-model="keyword"
              class="gray_search ver2"
              type="search"
              placeholder="검색어를 입력해주세요."
              @keydown="handleKeydown"
            />
            <button
              type="button"
              class="btnClear"
              @click="handleSearch"
            ></button>
          </div>
          <digital-warranty-item
            v-for="item in items"
            :key="item.tokenId"
            :item="item"
            :isShowButton="true"
          />
        </div>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import DigitalAPI from '@/apis/DigitalAPI';
import BasketButton from '../../../components/layouts/components/headers/components/BasketButton/BasketButton.vue';
import { reactive, toRefs } from 'vue-demi';
import DigitalWarrantyItem from '../../../components/ui/DigitalWarrantyItem/DigitalWarrantyItem.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import SildFooter from '../../../components/layouts/components/footers/SildFooter.vue';
export default {
  components: { BasketButton, DigitalWarrantyItem, SildFooter },

  setup() {
    const { info } = useUserInfo();
    const state = reactive({
      items: [],
      keyword: '',
    });

    const pagination = {
      page: 0,
      limit: 20,
      total: 0,
    };

    const loadDatas = async () => {
      const { data } = await DigitalAPI.getWarrantyList(info?.mem_id, {
        target: state.keyword,
        offset: 0,
        limit: 1000,
        sort: 'nft_token.created_at',
      });

      state.items = data.body.result.items.map(item => ({
        ...item,
        owner_id: info.mem_userid,
      }));
      pagination.total = data.body.result.warranty_item_size;
    };

    loadDatas();

    return {
      ...toRefs(state),
      handleSearch() {
        loadDatas();
      },
      handleKeydown(e) {
        if (e.key === 'Enter') {
          this.handleSearch();
        }
      },
    };
  },
};
</script>

<style></style>
