<template>
  <div class="wrap mypage-view">
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <div class="left_header">
            <a class="menu on">{{ $t('Mypage.Mypage') }}</a>
            <router-link class="menu" to="/mypage/digital">
              {{ $t('Mypage.Digital') }}
            </router-link>
          </div>
          <div class="right_header">
            <basket-button />
          </div>
        </nav>
      </div>
    </header>
    <div class="layout_body">
      <div class="list_layout">
        <div class="inner p04 my_join">
          <!--비로그인시ㄱ-->
          <div
            v-if="!info"
            class="non_login"
            @click="$router.push('/auth/login')"
          >
            <h6 class="font6 mb6">{{ $t('Mypage.SILDSign') }}</h6>
            <p class="font2">{{ $t('Mypage.AlertPrimary') }}</p>
          </div>
          <!--로그인시ㄱ-->
          <div v-else class="ing_login">
            <div class="grade_info disflex">
              <router-link
                class="profil_img"
                style="overflow: hidden; position: relative"
                to="/mypage/profile"
              >
                <image-bg-src v-if="info.img_url" :src="info.img_url" />
                <div v-else class="defaultImg"></div>
              </router-link>
              <p class="ellipsis userid" @click="handleClickCopy">
                {{ info.mem_userid }}
              </p>
              <div class="mem_grade flex_right">STARTER</div>
            </div>
            <div v-if="info.mem_profile" class="introduce bg_f7">
              <div style="word-break: break-all">
                {{ info.mem_profile }}
              </div>
            </div>
            <div class="grade_detail move_list">
              <ul>
                <li class="disflex">
                  <span>{{ grade }}</span>
                  <span class="colora8">{{ afterGrade }}</span>
                </li>
                <li :style="customStyle" class="gauge"></li>
                <li>
                  <span
                    >{{ $t('Mypage.PresentAmount') }}
                    {{ $t('Basket.MonetaryUnit') }}
                    {{ gradePrice ? gradePrice : '0' }}</span
                  >
                  <span>
                    {{ $t('Mypage.LevelUpAmount') }}
                    {{ $t('Basket.MonetaryUnit') }}
                    {{
                      afterGradePrice - gradePrice > 0
                        ? afterGradePrice - gradePrice
                        : '0'
                    }}
                    {{ $t('Mypage.Until') }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="inner border_top">
          <div class="ico_desc benefit">
            <ul class="disflex">
              <li>
                <router-link to="/mypage/point">
                  <div>
                    <div class="desc_img point"></div>
                    <div class="desc_txt">
                      <p>{{ $t('Mypage.Point') }}</p>
                      <p>
                        {{
                          info && info.mem_point
                            ? info.mem_point.numberFormat()
                            : '-'
                        }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/mypage/coupon">
                  <div>
                    <div class="desc_img coupon"></div>
                    <div class="desc_txt">
                      <p>{{ $t('Mypage.Coupon') }}</p>
                      <p>
                        {{
                          userInfoData && userInfoData.coupon_cnt
                            ? userInfoData.coupon_cnt
                            : '-'
                        }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/mypage/review">
                  <div>
                    <div class="desc_img review"></div>
                    <div class="desc_txt">
                      <p>{{ $t('Mypage.Review') }}</p>
                      <p>
                        {{
                          info && info.mem_review
                            ? info.mem_review.numberFormat()
                            : '-'
                        }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/mypage/digital">
                  <div>
                    <div class="desc_img guarantee"></div>
                    <div class="desc_txt">
                      <p>{{ $t('Mypage.Guarantee') }}</p>
                      <p>
                        {{
                          info && info.mem_nft_plus
                            ? info.mem_nft_plus.numberFormat()
                            : '-'
                        }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Mypage.ShoppingInfo') }}</h6>
          <div class="inner p04">
            <ul class="move_list">
              <li>
                <router-link class="arr_right" to="/mypage/recently">
                  {{ $t('Mypage.RecentlyProduct') }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="arr_right"
                  to="/mypage/order-delivery-search"
                >
                  {{ $t('Mypage.OrderInquiry') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/status-change-order">
                  {{ $t('Mypage.CancelList') }}
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link class="arr_right" to="/mypage/transaction-proof">-->
              <!--                  {{ $t('Mypage.DocumentEvidence') }}-->
              <!--                </router-link>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Mypage.CS') }}</h6>
          <div class="inner p04">
            <ul class="move_list">
              <li>
                <router-link
                  class="arr_right"
                  to="/mypage/service/direct-inquiry"
                >
                  {{ $t('Mypage.InquiryList') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/notice">
                  {{ $t('Mypage.Notice') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/faq">
                  {{ $t('Mypage.FAQ') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/inquiry-store">
                  {{ $t('Mypage.EnterInquiry') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Mypage.MyInfo') }}</h6>
          <div class="inner p04">
            <ul class="move_list">
              <li>
                <router-link class="arr_right" to="/mypage/profile">
                  {{ $t('Mypage.ProfileManage') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/update-info">
                  {{ $t('Mypage.InfoEdit') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/delivery-list">
                  {{ $t('Mypage.AddressList') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/mystyle">
                  {{ $t('Mypage.MystyleManage') }}
                </router-link>
              </li>
              <li>
                <router-link class="arr_right" to="/mypage/withdrawal">
                  {{ $t('Mypage.Withdraw') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('Mypage.Setting') }}</h6>
          <div class="inner p04">
            <ul class="move_list">
              <li>
                <router-link class="arr_right" to="mypage/setting">
                  {{ $t('Mypage.Setting') }}
                </router-link>
              </li>
              <li v-if="device_type !== 'pc'" class="last_list">
                <a class="btn_pop_open" @click="handleClickClear">
                  {{ $t('Mypage.CashDelete') }}
                </a>
              </li>
            </ul>
          </div>
          <router-link
            v-if="notices.length > 0"
            :to="`/mypage/notice?id=${notices[noticeRollingIdx].id}`"
            class="speaker"
          >
            {{ notices[noticeRollingIdx].title }}
          </router-link>
        </div>
        <company-info />
      </div>
    </div>
    <sild-footer :isToggleNav="true" class="items-sild-footer" />
    <input
      v-if="info"
      id="clipboardUrl"
      :value="info.mem_userid"
      style="opacity: 0; position: absolute; top: -100%"
      type="text"
    />
  </div>
</template>

<script>
import SildFooter from '@/components/layouts/components/footers/SildFooter.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import useToast from '@/composables/dialogs/useToast';
import BasketButton from '../../components/layouts/components/headers/components/BasketButton/BasketButton.vue';
import CompanyInfo from '../../components/layouts/components/footers/CompanyInfo.vue';
import { checkPlatform } from '@/utils/Buy';
import useConfirm from '@/composables/dialogs/useConfirm';
import { toggleNav } from '@/utils/GlobalUtils';
import { onMounted, onUnmounted, reactive, toRefs } from 'vue-demi';
import CommonAPI from '@/apis/CommonAPI';
import AuthAPI from '@/apis/AuthAPI';

export default {
  components: { SildFooter, ImageBgSrc, BasketButton, CompanyInfo },
  setup(_, context) {
    window.scrollTo(0, 0);

    const { info } = useUserInfo();
    const { showConfirm } = useConfirm();
    const { addMsg } = useToast();
    const state = reactive({
      customStyle: { '--userGradeColorWidth': '0%' },
      noticeRollingTimer: null,
      noticeRollingIdx: 0,
      notices: [],
      afterGradePrice: Number,
      gradePrice: Number,
      afterGrade: String,
      grade: String,
      userInfoData: null,
    });

    const loadNotices = async () => {
      const { data } = await CommonAPI.getNoticeList();

      const items = data.result_data.map((item) => ({
        id: item.cbn_id,
        title: item.cbn_title,
        date: item.upd_dtm.substr(0, 10),
        content: item.cbn_content,
      }));

      state.notices = [...state.notices, ...items];
    };
    const UserGrade = async () => {
      if (info?.mem_id) {
        const { data } = await AuthAPI.getUserGrade({ mem_id: info?.mem_id });
        console.log(data);
        if (data) {
          state.afterGradePrice = data.after_grade_price
            ? data.after_grade_price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '';
          state.gradePrice = data.grade_price
            ? data.grade_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '';
          state.afterGrade = data.after_grade ? data.after_grade : '';
          state.grade = data.grade;
          // 초과달성시에는 그냥 100%
          const colorWidth =
            Number(data.grade_price) >= Number(data.after_grade_price)
              ? '100%'
              : (Number(data.grade_price) / Number(data.after_grade_price)) *
                  100 +
                '%';
          state.customStyle = {
            '--userGradeColorWidth': colorWidth,
          };
        } else {
          state.afterGradePrice = '0';
          state.gradePrice = '0';
          state.afterGrade = '0';
          state.grade = '';
        }
      }
    };

    onMounted(async () => {
      await loadNotices();
      if (info?.mem_id) {
        await UserGrade();
      }

      state.noticeRollingTimer = setInterval(() => {
        state.noticeRollingIdx =
          (state.noticeRollingIdx + 1) % state.notices.length;
      }, 5000);
      if (localStorage.getItem('mem_id')) {
        await AuthAPI.getMemberInfo(localStorage.getItem('mem_id')).then(
          (res) => {
            console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
            console.log(info);
            console.log(res);
            console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
            state.userInfoData = res.data.result_data;
          }
        );
      }
    });

    onUnmounted(() => {
      state.noticeRollingTimer = clearInterval(state.noticeRollingTimer);
    });

    const clear = () => {
      const mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase()
      );
      const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
      const sendData = 'cache_clear';

      if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //모바일 접속이고 아이폰일 경우
        // (JavaScript --> 앱)
        window.webkit.messageHandlers.cacheclear.postMessage(sendData);
      } else if (mobile && varUA.indexOf('android') > -1) {
        // 모바일 접속이고, Android 일경우
        window.Android.cacheclear(); // (Front --> 앱)
      }
    };
    return {
      ...toRefs(state),
      info,
      device_type: checkPlatform(window.navigator.userAgent),
      handleClickClear() {
        showConfirm(context.root.$t('Mypage.AlertCash'), clear);
      },
      handleClickCopy() {
        context.root.$copyText(info.mem_userid);
        addMsg(context.root.$t('Mypage.AlertCopy'));
      },
    };
  },

  created() {
    window.addEventListener('scroll', toggleNav);
  },
  destroyed() {
    window.removeEventListener('scroll', toggleNav);
  },
};
</script>

<style>
.userid {
  cursor: pointer;
  text-decoration: underline;
}
</style>
