<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.Setting')" />

    <div class="layout_body">
      <div class="">
        <h6 class="allPage_tit">{{ $t('Setting.DefaultSetting') }}</h6>
        <div class="inner p04">
          <!-- 로그인 안했을때 -->
          <router-link v-if="!userInfo" to="/auth/login">
            <div class="non_login">
              <h6 class="font6 mb6">{{ $t('Setting.SILDLogin') }}</h6>
              <p class="font2">{{ $t('Setting.PleaseLogin') }}</p>
            </div>
          </router-link>

          <!-- 로그인  d했을때 -->
          <ul v-if="userInfo" class="move_list type2">
            <li>
              <p class="tit">{{ userInfo.mem_userid }}</p>
              <p>
                <button
                  class="btn26bw60"
                  type="button"
                  @click="handleClickLogout"
                >
                  {{ $t('Setting.Logout') }}
                </button>
              </p>
            </li>
            <li>
              <p class="tit">{{ $t('Setting.AutoLogin') }}</p>
              <p>
                <switch-box
                  :value="isChecked"
                  @change="handleAutoLoginCheckEvent"
                />
              </p>
            </li>
          </ul>
          <!-- 로그인했을때 :end -->
        </div>
      </div>
      <!-- 비로그인 안보임 : start-->
      <div v-if="userInfo">
        <div v-if="device_type !== 'pc'" class="border_top">
          <h6 class="allPage_tit">{{ $t('Setting.AuthoritySetting') }}</h6>
          <div class="inner p04">
            <ul class="move_list type2">
              <li>
                <p class="tit">{{ $t('Setting.PushSetting') }}</p>
                <p>
                  <switch-box :value="pushYn === 'y'" @change="handlePushYn" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 비로그인 안보임 : end-->
      <div class="border_top">
        <h6 class="allPage_tit">{{ $t('Setting.Information') }}</h6>
        <div class="inner p04">
          <ul class="move_list">
            <li>
              <a class="btn_pop_open" @click="isShowUsage = true">
                {{ $t('Join.UsageTerm') }}
              </a>
            </li>

            <li>
              <a class="btn_pop_open" @click="isShowPrivate = true">
                {{ $t('Setting.TermPersonal') }}
              </a>
            </li>
            <li>
              <a class="btn_pop_open" @click="isShowLicense = true">
                {{ $t('Setting.OpenLicense') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 비로그인 안보임 : start-->
      <div v-if="device_type !== 'pc'" class="border_top">
        <h6 class="allPage_tit">{{ $t('Setting.AppShare') }}</h6>
        <div class="inner p04">
          <ul class="move_list">
            <li>
              <a
                class="btn_pop_open"
                data-popup="sns_share_popup"
                @click="handleClickShare"
                >{{ $t('Setting.SILDShare') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- 비로그인 안보임 : end-->
      <!-- <div class="border_top">
        <h6 class="allPage_tit">앱 정보</h6>
        <div class="inner p04">
          <ul class="move_list type2">
            <li>
              <p class="tit">현재버전</p>
              <p class="tit">1.0.0</p>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
    <usage-popup v-if="isShowUsage" @close="isShowUsage = false" />
    <private-popup v-if="isShowPrivate" @close="isShowPrivate = false" />
    <license-popup v-if="isShowLicense" @close="isShowLicense = false" />
    <sild-footer />
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import useToast from '../../composables/dialogs/useToast';
import SwitchBox from '@/components/Inputs/SwitchBox/SwitchBox.vue';
import UsagePopup from './popups/UsagePopup.vue';
import PrivatePopup from './popups/PrivatePopup.vue';
import LicensePopup from './popups/LicensePopup.vue';
import { checkPlatform } from '@/utils/Buy';
import AuthAPI from '@/apis/AuthAPI';
import useShare from '@Composables/dialogs/useShare';

export default {
  components: {
    BackTitleBasket,
    SildFooter,
    SwitchBox,
    UsagePopup,
    PrivatePopup,
    LicensePopup,
  },
  setup(_, context) {
    const { info, clearUserInfo, setUserInfo } = useUserInfo();
    const { addMsg } = useToast();
    const userInfo = ref(info);
    const { showShare } = useShare();
    const state = reactive({
      isShowUsage: false,
      isShowPrivate: false,
      isShowLicense: false,
      isChecked: false,
      autoLogin: null,
      pushYn: localStorage.getItem('push_yn')
        ? localStorage.getItem('push_yn')
        : info
        ? info.push_yn
        : '',
      urls: '',
    });
    const handleAutoLoginCheck = async () => {
      if (info) {
        info.auto_login = localStorage.getItem('autoLogin')
          ? localStorage.getItem('autoLogin')
          : '';
        setUserInfo(info);
        state.isChecked = info.auto_login === 'y';
        return state.isChecked;
      } else {
        console.log('not info');
      }
    };
    /* 자동 로그인 체크 시 실행 */
    const handleAutoLoginCheckEvent = async (check) => {
      check ? (state.autoLogin = 'y') : (state.autoLogin = 'n');
      const { data } = await AuthAPI.getMemberAutoLoginYn({
        mem_id: info.mem_id,
        auto_login: state.pushYn,
      });
      data.result
        ? check
          ? addMsg(context.root.$t('Setting.AutoLoginON'))
          : addMsg(context.root.$t('Setting.AutoLoginOff'))
        : addMsg(context.root.$t('Setting.Error'));
      if (data.result) {
        window.localStorage.setItem('autoLogin', state.autoLogin);
      }
    };
    const updatePushYn = async (pushYn) => {
      const { data } = await AuthAPI.setUserPushYn({
        mem_id: info.mem_id,
        push_yn: pushYn ? 'y' : 'n',
      });
      data.result
        ? addMsg(context.root.$t('Setting.PushON'))
        : addMsg(context.root.$t('Setting.PushOff'));
      window.localStorage.setItem('push_yn', pushYn ? 'y' : 'n');
    };

    handleAutoLoginCheck();
    watch(
      () => state.pushYn,
      () => {
        handleAutoLoginCheck();
      }
    );
    const checkMobile = () => {
      const mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase()
      );
      const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
      console.log(mobile, varUA, '???');
      if (varUA.indexOf('android') > -1) {
        state.urls =
          'https://play.google.com/store/apps/details?id=com.sildCompany.sildApp&pli=1';
      } else if (
        varUA.indexOf('iphone') > -1 ||
        varUA.indexOf('ipad') > -1 ||
        varUA.indexOf('ipod') > -1
      ) {
        state.urls = 'https://apps.apple.com/kr/app/sild/id1558640613';
      }
    };
    checkMobile();
    return {
      userInfo,
      handleAutoLoginCheckEvent,
      handleAutoLoginCheck,
      device_type: checkPlatform(window.navigator.userAgent),
      ...toRefs(state),
      handleClickLogout() {
        clearUserInfo();
        userInfo.value = null;
        localStorage.setItem('myauth', null);
        localStorage.setItem('reloaded', null);
        addMsg(context.root.$t('Setting.AlertLogout'));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      // push 알림 사용 설정
      handlePushYn(pushYn) {
        updatePushYn(pushYn);
      },

      handleClickShare() {
        showShare({
          url: state.urls,
          title: 'SILD',
          desc: 'WE”RE LOOKING IN SILD',
          image: ``,
        });
      },
    };
  },
};
</script>

<style></style>
